import {
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  GET_NOTIFICATIONS,
  /*   MARK_NOTI_AS_COMPLETED,
  MARK_NOTI_AS_COMPLETED_FAILURE,
  MARK_NOTI_AS_COMPLETED_SUCCESS,
  ADD_NOTIFICATION,
  ADD_NOTIFICATION_SUCCESS,
  ADD_NOTIFICATION_FAILURE */
} from "../actions/actionTypes";
import handleFailedAuth from "../helpers/handleExpiredToken";

import { Notifications } from "../api/agent";
// import showToast from "../helpers/toastNotification";

import { put, call, takeEvery } from "redux-saga/effects";

function* getNotificationList(action) {
  try {
    const payload = yield call(Notifications.getNotications);
    yield put({ type: GET_NOTIFICATIONS_SUCCESS, payload });
  } catch (error) {
    if (error.response.status === 401 || error.response.statsCode === 401) {
      handleFailedAuth();
      return;
    }
    const errResp = error.response.body;
    yield put({ type: GET_NOTIFICATIONS_FAILURE, errResp });
  }
}

export function* watchGetNotificationList() {
  yield takeEvery(GET_NOTIFICATIONS, getNotificationList);
}
/* 
function* markNotificationAsCompleted(action) {
  try {
    const payload = yield call(
      Notifications.markNotificationAsCompleted,
      action.payload
    );
    yield put({ type: MARK_NOTI_AS_COMPLETED_SUCCESS, payload });
    showToast("s", "Reminder Updated");
  } catch (error) {
    if (error.response.status === 401 || error.response.statsCode === 401) {
      handleFailedAuth();
      return;
    }
    const errResp = error.response.body;
    showToast("e", errResp || "Error while updating");

    yield put({ type: MARK_NOTI_AS_COMPLETED_FAILURE, errResp });
  }
}

export function* watchMarkNotificationAsCompleted() {
  yield takeEvery(MARK_NOTI_AS_COMPLETED, markNotificationAsCompleted);
}

function* addNotification(action) {
  try {
    const payload = yield call(Notifications.addNotification, action.payload);
    yield put({ type: ADD_NOTIFICATION_SUCCESS, payload });
    showToast("s", "Reminder Added");
  } catch (error) {
    if (error.response.status === 401 || error.response.statsCode === 401) {
      handleFailedAuth();
      return;
    }
    const errResp = error.response.body;
    showToast("e", errResp || "Unable To Add Reminder");
    yield put({ type: ADD_NOTIFICATION_FAILURE, errResp });
  }
}

export function* watchAddNotification() {
  yield takeEvery(ADD_NOTIFICATION, addNotification);
}
 */
