import { Opportunities } from "../api/agent";
import { takeLatest, call, put } from "redux-saga/effects";
import {
  ADD_OPPORTUNITY,
  ADD_OPPORTUNITY_SUCCESS,
  ADD_OPPORTUNITY_FAILURE,
  EDIT_OPPORTUNITY,
  EDIT_OPPORTUNITY_SUCCESS,
  EDIT_OPPORTUNITY_FAILURE,
  GET_OPPORTUNITIES,
  GET_OPPORTUNITIES_SUCCESS,
  GET_OPPORTUNITY,
  GET_OPPORTUNITY_SUCCESS,
  DELETE_OPPORTUNITY,
  DELETE_OPPORTUNITY_SUCCESS,
  DELETE_OPPORTUNITY_FAILURE,
} from "../actions/actionTypes";
import showToast from "../helpers/toastNotification";

function* getOpportunities(action) {
  try {
    const { page, searchValue } = action.payload;
    const payload = yield call(
      Opportunities.getOpportunities,
      page,
      searchValue
    );
    yield put({ type: GET_OPPORTUNITIES_SUCCESS, payload });
  } catch (e) {
    console.log("error");
  }
}

export function* watchGetOpportunities() {
  yield takeLatest(GET_OPPORTUNITIES, getOpportunities);
}

function* addOpportunity(action) {
  try {
    const { data } = action.payload;
    const payload = yield call(Opportunities.addOpportunity, data);
    yield put({ type: ADD_OPPORTUNITY_SUCCESS, payload });
    showToast("s", "Opportunities Added Successfully");
  } catch (e) {
    const errResp = e.response.body;
    yield put({ type: ADD_OPPORTUNITY_FAILURE, errResp });
    showToast("e", e.message || "Unable To Add Opportunities");
  }
}

export function* watchAddOpportunity() {
  yield takeLatest(ADD_OPPORTUNITY, addOpportunity);
}

function* getOpportunity(action) {
  try {
    const { slug } = action.payload;
    const payload = yield call(Opportunities.getOpportunity, slug);
    yield put({ type: GET_OPPORTUNITY_SUCCESS, payload });
  } catch (e) {
    console.log(e);
  }
}

export function* watchGetOpportunity() {
  yield takeLatest(GET_OPPORTUNITY, getOpportunity);
}

function* editOpportunity(action) {
  try {
    const { data, slug } = action.payload;
    const payload = yield call(Opportunities.editOpportunity, data, slug);
    showToast("s", "Opportunity successfully edited");
    yield put({ type: EDIT_OPPORTUNITY_SUCCESS, payload });
  } catch (e) {
    const errResp = e.response.body;
    yield put({ type: EDIT_OPPORTUNITY_FAILURE, errResp });
    showToast("e", "Unable to edit opportunity");
  }
}

export function* watchEditOpportunity() {
  yield takeLatest(EDIT_OPPORTUNITY, editOpportunity);
}

function* deleteOpportunity(action) {
  try {
    const { slug } = action.payload;
    const payload = yield call(Opportunities.deleteOpportunity, slug);
    showToast("s", "Opportunity successfully deleted");
    yield put({ type: DELETE_OPPORTUNITY_SUCCESS, payload });
  } catch (e) {
    const errResp = e.response.body;
    yield put({ type: DELETE_OPPORTUNITY_FAILURE, errResp });
    showToast("e", "Unable to delete opportunity");
  }
}

export function* watchDeleteOpportunity() {
  yield takeLatest(DELETE_OPPORTUNITY, deleteOpportunity);
}
