import React, { Component } from "react";
import * as Yup from "yup";
import { Row, Col, Button } from "reactstrap";
import { withFormik, Field } from "formik";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import clsx from "clsx";

import FormField from "../../../../shared/components/form/FormField";
import TextareaField from "../../../../shared/components/form/Textarea";
import TagsInputField from "../../../../shared/components/form/TagsInputField";
import SelectField from "../../../../shared/components/form/SelectField";
import FileInput from "../../../../shared/components/FileInput";
import LoadingIndicator from "../../../../shared/components/LoadingIndicator";

import Image from "react-graceful-image";
import ImageUploader from "react-images-upload";
import { uploadFile } from "../../../../actions/resource";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      loadingPartner: true,
      partnerInfo: null,
      uploadingImage: false,
      uploadingVideo: false,
    };
  }

  handleUploadImageFile = (files) => {
    this.setState({ uploadingImage: true, error: false, fileError: null });
    this.props.uploadFile({
      tags: "partner, profile",
      folder: "partners",
      files: files,
    });
  };

  handleFileSelect = (files) => {
    this.setState({ uploadingVideo: true, error: null });
    this.props.uploadFile({
      tags: "partner, profile",
      folder: "partners",
      files: files,
    });
  };

  _cancelWizard = () => {
    const {
      values: { slug },
    } = this.props;

    if (slug) this.props.history.push(`/partners/${slug}`);
    else this.props.history.push("/");
  };

  handleFileError = (e) => {
    this.setState({ error: e });
    this.setState({ uploadingVideo: false, error: e });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.uploadProp && nextProps.uploadProp.uploadFileSuccess) {
      if (
        prevState.uploadingVideo === true &&
        nextProps.uploadProp.fileId !== nextProps.values.imageId
      ) {
        nextProps.setFieldValue("videoId", nextProps.uploadProp.fileId);
        nextProps.setFieldValue("videoUrl", nextProps.uploadProp.displayUrl);

        return {
          uploadingVideo: false,
        };
      } else if (
        prevState.uploadingImage === true &&
        nextProps.uploadProp.fileId !== nextProps.values.imageId
      ) {
        nextProps.setFieldValue("imageId", nextProps.uploadProp.fileId);
        nextProps.setFieldValue("imageUrl", nextProps.uploadProp.displayUrl);
        return {
          uploadingImage: false,
        };
      } else {
        return null;
      }
    } else return null;
  }

  render() {
    const {
      handleSubmit,

      // Formik HOC props
      values,
      errors,
      isSubmitting,
    } = this.props;

    return (
      <div className="wizard-steps horizontal">
        <div className="card-box px-4">
          <form onSubmit={handleSubmit} className="py-2">
            <div>
              <div className="card-header pb-2">
                <div className="card-header--title">
                  <h3 className="font-weight-bold">About your business</h3>
                </div>
              </div>
              <Row className="my-3">
                <Col md={6} lg={6} xl={6}>
                  {this.state.uploadingImage ? (
                    <LoadingIndicator />
                  ) : (
                    <Image
                      src={values.imageUrl || "/assets/images/default_user.png"}
                      height="250px"
                      alt="..."
                    />
                  )}
                </Col>
                <Col>
                  <ImageUploader
                    withIcon={true}
                    buttonText="Choose image"
                    singleImage={true}
                    className={errors.imageUrl ? "is-invalid" : ""}
                    onChange={this.handleUploadImageFile}
                    imgExtension={[".jpeg", ".jpg", ".gif", ".png", ".gif"]}
                    maxFileSize={5242880}
                  />
                  {errors.imageUrl && (
                    <span className="invalid-feedback font-size-xl">
                      Image is required
                    </span>
                  )}
                </Col>
              </Row>
              <Row>
                <div className="form-group col-12 col-sm-12 col-md-12 col-lg-12 inputblock">
                  <label htmlFor="exampleInputEmail1">Business Name:</label>
                  <Field
                    name="name"
                    component={FormField}
                    placeholder="Business Name"
                  />
                </div>
              </Row>
              <Row>
                <div className="form-group col-12 col-sm-12 col-md-12 col-lg-12 inputblock">
                  <label htmlFor="exampleInputEmail1">Category:</label>
                  <Field
                    name="category"
                    component={SelectField}
                    options={[
                      {
                        value: "PM",
                        label: "Property Manager",
                      },
                      {
                        value: "R",
                        label: "Real Estate Agent",
                      },
                    ]}
                    placeholder="Select Category"
                  />
                </div>
              </Row>
              <Row>
                <div className="form-group col-12 col-sm-12 col-md-12 col-lg-12 inputblock">
                  <label htmlFor="exampleInputEmail1">
                    Management Platform:
                  </label>
                  <Field
                    name="platform"
                    component={SelectField}
                    options={[
                      {
                        value: "appfolio",
                        label: "AppFolio",
                      },
                      {
                        value: "cozy",
                        label: "Cozy.co",
                      },
                      {
                        value: "propertyware",
                        label: "Property Ware",
                      },
                      {
                        value: "rentmanager",
                        label: "Rent Manager",
                      },
                      {
                        value: "homeroom",
                        label: "HomeRoom",
                      },
                      {
                        value: "quickbook",
                        label: "QuickBooks",
                      },
                      {
                        value: "hemlane",
                        label: "Hemlane",
                      },
                      {
                        value: "other",
                        label: "Other",
                      },
                      {
                        value: "none",
                        label: "None",
                      },
                    ]}
                    placeholder="Select Platform"
                  />
                </div>
              </Row>
              <Row>
                <div className="form-group col-12 col-sm-12 col-md-12 col-lg-12 inputblock">
                  <label htmlFor="exampleInputEmail1">Bio:</label>
                  <Field
                    name="bio"
                    component={TextareaField}
                    placeholder="Your Bio"
                    preserveMultiLine={true}
                  />
                </div>
              </Row>
              <Row>
                <div className="form-group col-12 col-sm-12 col-md-12 col-lg-12 inputblock">
                  <label htmlFor="exampleInputEmail1">Video:</label>
                  <Row>
                    <Col md={6} lg={8} xl={6}>
                      <Field
                        name="videoUrl"
                        component={FormField}
                        placeholder="Video Url"
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: "2px",
                          left: "94%",
                        }}
                        className={clsx("spinner-border m-2 text-primary ", {
                          "d-none": !this.state.uploadingVideo,
                        })}
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    </Col>
                    <Col md={1} lg={1} xl={1}>
                      <h4> or </h4>
                    </Col>
                    <Col md={6} lg={4} xl={4}>
                      <div className="d-inline-block">
                        <FileInput
                          onSuccess={(files) => {
                            this.handleFileSelect(files);
                          }}
                          onError={this.handleFileError}
                          multiple={false}
                          id={"file"}
                          maxSize={1000}
                          disabled={this.state.uploadingVideo}
                          placeholder={"upload file"}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Row>
              <Row>
                <div className="form-group col-12 col-sm-12 col-md-12 col-lg-12 inputblock">
                  <label htmlFor="exampleInputEmail1">Service Tags:</label>
                  <Field
                    name="tags"
                    component={TagsInputField}
                    placeholder="Service Tags"
                  />
                </div>
              </Row>
            </div>

            <div className="actions p-4">
              <Button
                outline
                color="primary"
                onClick={this._cancelWizard}
                disabled={isSubmitting}
              >
                Cancel
              </Button>

              <Button color="primary" disabled={false} type="submit">
                Next
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const AboutForm = withFormik({
  mapPropsToValues: (props) => ({
    slug: props.partner.slug,
    name: props.partner.name,
    bio: props.partner.bio,
    imageUrl: props.partner.imageUrl,
    imageId: props.partner.imageId,
    videoUrl: props.partner.videoUrl,
    videoId: props.partner.videoId,
    tags: props.partner.tags,
    category: props.partner.category,
    questions: props.partner.questions,
    highlights: props.partner.highlights,
    plans: props.partner.plans,
  }),

  validationSchema: Yup.object().shape({
    name: Yup.string().required("Name is required"),
    bio: Yup.string().required("Bio is required"),
    category: Yup.string().required("Category is required"),
    imageUrl: Yup.string().required(),
    tags: Yup.array().min(2).required(),
  }),

  handleSubmit: (values, { props }) => {
    props.nextHandler(values);
  },
})(About);

const mapStateToProps = (state) => ({
  uploadProp: state.upload,
});

const mapDispatchToProps = (dispatch) => ({
  uploadFile: (data) => uploadFile(data, dispatch),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AboutForm));
