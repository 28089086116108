import React from "react";
import { withFormik, Field, FieldArray } from "formik";
import * as Yup from "yup";
import { Button } from "reactstrap";
import TextareaField from "../../../../shared/components/form/Textarea";
import FormField from "../../../../shared/components/form/FormField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import showToast from "../../../../helpers/toastNotification";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const HighlightsForm = (props) => {
  const {
    // Formik HOC props
    values,
    errors,
    isSubmitting,
    handleSubmit,

    // Loki props
    backHandler,
    cantBack,
  } = props;

  if (
    props.isSubmitting &&
    props.isValidating &&
    errors.highlights &&
    errors.highlights.filter(Boolean).length > 0
  ) {
    showToast("w", "Remove any un-filled highlights");
  }

  return (
    <div className="wizard-steps horizontal">
      <div className="card-box p-4">
        <form onSubmit={handleSubmit} className="px-2">
          <FieldArray
            name="highlights"
            render={(arrayHelpers) => (
              <div>
                <div className="card-header pb-2">
                  <div className="card-header--title">
                    <h3 className="mb-0 py-2 font-weight-bold">
                      What differentiates you from others
                    </h3>
                  </div>
                  <div className="card-header--actions">
                    <Button
                      size="lg"
                      color="first"
                      onClick={() => {
                        if (values.highlights.length < 4) {
                          return arrayHelpers.push({
                            title: "",
                            subtitle: "",
                          });
                        }
                      }}
                      className="py-1 px-3 text-uppercase"
                    >
                      <span className="btn-wrapper--icon">
                        <FontAwesomeIcon icon={faPlus} />
                      </span>
                      <span className="btn-wrapper--label">Add Highlight</span>
                    </Button>
                  </div>
                </div>
                {values.highlights && values.highlights.length === 0 && (
                  <p>You don't have any Highlight.</p>
                )}
                {values.highlights &&
                  values.highlights.map((q, i) => (
                    <div key={i}>
                      <Button
                        color="second"
                        className="float-right"
                        onClick={(e) => {
                          e.preventDefault();
                          arrayHelpers.pop(i);
                        }}
                      >
                        Remove
                      </Button>
                      <div className="clearfix" />
                      <div className="row">
                        <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6 inputblock">
                          <label htmlFor="exampleInputEmail1">Title:</label>
                          <Field
                            name={`highlights.${i}.title`}
                            component={FormField}
                            placeholder="Title"
                          />
                        </div>
                        <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6  inputblock">
                          <label htmlFor="exampleInputEmail1">Subtitle:</label>
                          <Field
                            name={`highlights.${i}.subtitle`}
                            component={TextareaField}
                            placeholder="Subtitle"
                            rows="2"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          />

          <div className="actions p-4">
            <Button
              outline
              color="primary"
              onClick={backHandler}
              disabled={cantBack || isSubmitting}
            >
              Back
            </Button>

            <Button color="primary" disabled={isSubmitting} type="submit">
              Next
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default withFormik({
  mapPropsToValues: (props) => ({
    slug: props.partner.slug,
    name: props.partner.name,
    bio: props.partner.bio,
    imageUrl: props.partner.imageUrl,
    imageId: props.partner.imageId,
    videoUrl: props.partner.videoUrl,
    videoId: props.partner.videoId,
    tags: props.partner.tags,
    category: props.partner.category,
    questions: props.partner.questions,
    highlights: props.partner.highlights,
    plans: props.partner.plans,
  }),

  validationSchema: Yup.object().shape({
    highlights: Yup.array().of(
      Yup.object({
        title: Yup.string()
          .required("Title is required")
          .max(20, "Title too long. Max characters 20"),
        subtitle: Yup.string()
          .required("Subtitle is required")
          .max(100, "Subtitle too long. Max characters 100"),
      })
    ),
  }),
  handleSubmit: (values, { props }) => {
    props.nextHandler(values);
  },
})(HighlightsForm);
