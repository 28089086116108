import React from "react";
import { withFormik, Field, FieldArray } from "formik";
import { Button } from "reactstrap";
import TextareaField from "../../../../shared/components/form/Textarea";
import FormField from "../../../../shared/components/form/FormField";

import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import showToast from "../../../../helpers/toastNotification";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const PricingForm = (props) => {
  const {
    // Formik HOC props
    values,
    errors,
    isSubmitting,
    handleSubmit,

    // Loki props
    backHandler,
    cantBack,
  } = props;

  if (
    props.isSubmitting &&
    props.isValidating &&
    errors.plans &&
    errors.plans.filter(Boolean).length > 0
  ) {
    showToast("w", "Remove any un-filled Plan's");
  }

  return (
    <div className="wizard-steps horizontal">
      <div className="card-box p-4">
        <form onSubmit={handleSubmit} className="px-2">
          <FieldArray
            name="plans"
            render={(arrayHelpers) => (
              <div>
                <div className="card-header pb-2">
                  <div className="card-header--title">
                    <h3 className="py-2 font-weight-bold">
                      Different paid services you offer
                    </h3>
                  </div>
                  <div className="card-header--actions">
                    <Button
                      size="lg"
                      color="first"
                      onClick={() => {
                        if (values.plans.length < 4) {
                          return arrayHelpers.push({
                            planName: "",
                            planPrice: "",
                            planDescription: "",
                          });
                        }
                      }}
                      className="py-1 px-3 text-uppercase"
                    >
                      <span className="btn-wrapper--icon">
                        <FontAwesomeIcon icon={faPlus} />
                      </span>
                      <span className="btn-wrapper--label">Add Plan</span>
                    </Button>
                  </div>
                </div>
                {values.plans.length === 0 && <p>You don't have any plans.</p>}
                {values.plans.map((p, i) => (
                  <div key={i}>
                    <h4 className="float-left mb-3">
                      {p.planName || `Plan#${i + 1}`}
                    </h4>
                    <Button
                      color="second"
                      className="float-right"
                      onClick={() => {
                        arrayHelpers.pop(i);
                      }}
                    >
                      Remove
                    </Button>
                    <div className="clearfix" />
                    <div className="row">
                      <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6 inputblock">
                        <label htmlFor="exampleInputEmail1">Plan Name:</label>
                        <Field
                          name={`plans.${i}.planName`}
                          component={FormField}
                          placeholder="Plan Name"
                        />
                      </div>
                      <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6  inputblock">
                        <label htmlFor="exampleInputEmail1">Plan Price:</label>
                        <Field
                          name={`plans.${i}.planPrice`}
                          component={FormField}
                          placeholder="$200"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-12 col-sm-12 col-md-12 col-lg-12 inputblock">
                        <label htmlFor="exampleInputEmail1">
                          Plan Description:
                        </label>
                        <Field
                          name={`plans.${i}.planDescription`}
                          component={TextareaField}
                          // style={"white-space": "pre-line"}
                          preserveLine="true"
                          placeholder="Plan Description"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-12 col-sm-12 col-md-12 col-lg-12 inputblock">
                        <label htmlFor="exampleInputEmail1">Plan Url:</label>
                        <Field
                          name={`plans.${i}.planUrl`}
                          component={FormField}
                          placeholder="Paste your URL here..."
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          />

          <div className="actions p-4">
            <Button
              outline
              color="primary"
              onClick={backHandler}
              disabled={cantBack || isSubmitting}
            >
              Back
            </Button>

            <Button color="primary" disabled={isSubmitting} type="submit">
              Next
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default withFormik({
  mapPropsToValues: (props) => ({
    slug: props.partner.slug,
    name: props.partner.name,
    bio: props.partner.bio,
    imageUrl: props.partner.imageUrl,
    imageId: props.partner.imageId,
    videoUrl: props.partner.videoUrl,
    videoId: props.partner.videoId,
    tags: props.partner.tags,
    category: props.partner.category,
    questions: props.partner.questions,
    highlights: props.partner.highlights,
    plans: props.partner.plans,
  }),

  validationSchema: Yup.object().shape({
    plans: Yup.array().of(
      Yup.object({
        planName: Yup.string().required("Plan Name is required"),
        planPrice: Yup.string()
          .required("Price is required")
          .max(16, "Price is too large"),
        planDescription: Yup.string()
          .required("Plan Description is required")
          .max(200, "Max length should be less than 200"),
        planUrl: Yup.string().required("Plan Url is required"),
      })
    ),
  }),

  handleSubmit: (values, { props }) => {
    props.nextHandler(values);
  },
})(PricingForm);
