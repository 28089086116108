import { HIDE_ADD_ASSETS_MODAL, SHOW_ADD_ASSETS_MODAL } from "../actionTypes";

export const showAddAssetsModal = (dispatch, event) => {
  dispatch({
    type: SHOW_ADD_ASSETS_MODAL,
    payload: event,
  });
};

export const hideAddAssetsModal = (dispatch) => {
  dispatch({
    type: HIDE_ADD_ASSETS_MODAL,
  });
};
