import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { getIn } from "formik";
import clsx from "clsx";

class FormField extends PureComponent {
  render() {
    const {
      field,
      type,
      form: { touched, errors },
      size,
      placeholder,
    } = this.props;
    const touch = getIn(touched, field.name);
    const errorMessage = getIn(errors, field.name);
    const sizeClass = size ? "form-control-" + size : null;

    return (
      <div>
        <input
          {...field}
          {...this.props}
          type={type}
          className={clsx(`form-control ${sizeClass}`, {
            "is-invalid": touch && errorMessage,
          })}
          placeholder={placeholder}
        />
        <div className="invalid-feedback">{errorMessage}</div>
      </div>
    );
  }
}

FormField.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  value: PropTypes.string,
};

FormField.defaultProps = {
  placeholder: "",
  meta: null,
  type: "text",
};

export default FormField;
