import { put, call, takeLatest } from "redux-saga/effects";
import { Financial } from "../api/agent";
import handleFailedAuth from "../helpers/handleExpiredToken";
import showToast from "../helpers/toastNotification";

import {
  ADD_FINANCIAL_TO_ASSET,
  ADD_FINANCIAL_TO_ASSET_SUCCESS,
  ADD_FINANCIAL_TO_ASSET_FAILURE,
  GET_ASSET_FINANCIALS,
  GET_ASSET_FINANCIALS_SUCCESS,
  GET_ASSET_FINANCIALS_FAILURE,
  UPDATE_ASSET_FINANCIAL,
  UPDATE_ASSET_FINANCIAL_FAILURE,
  UPDATE_ASSET_FINANCIAL_SUCCESS,
  GET_NOTIFICATIONS,
  GET_ASSET_CHECKLIST,
  DELETE_ASSET_FINANCIAL,
  DELETE_ASSET_FINANCIAL_SUCCESS,
  DELETE_ASSET_FINANCIAL_FAILURE,
} from "../actions/actionTypes";

function* addFinancialToAsset(action) {
  try {
    const slug = action.payload.slug;
    const payload = yield call(Financial.addFinancialToAsset, action.payload);
    yield put({ type: ADD_FINANCIAL_TO_ASSET_SUCCESS, payload });
    showToast("s", "Financial Added Successfully");
    //  Checking for new notification when new financials are added
    yield put({ type: GET_NOTIFICATIONS });
    yield put({ type: GET_ASSET_CHECKLIST, payload: slug });
  } catch (error) {
    if (error.response.status === 401 || error.response.statsCode === 401) {
      handleFailedAuth();
      return;
    }
    showToast("e", "Error While Saving Financial");
    const err = error.response.body;
    yield put({ type: ADD_FINANCIAL_TO_ASSET_FAILURE, err });
  }
}

export function* watchAddFinancialToAsset() {
  yield takeLatest(ADD_FINANCIAL_TO_ASSET, addFinancialToAsset);
}

function* getAssetFinancials(action) {
  try {
    const payload = yield call(Financial.getAssetFinancials, action.payload);
    yield put({ type: GET_ASSET_FINANCIALS_SUCCESS, payload });
  } catch (error) {
    if (error.response.status === 401 || error.response.statsCode === 401) {
      handleFailedAuth();
      return;
    }
    const err = error.response.body;
    yield put({ type: GET_ASSET_FINANCIALS_FAILURE, err });
  }
}

export function* watchGetAssetFinancials() {
  yield takeLatest(GET_ASSET_FINANCIALS, getAssetFinancials);
}

function* updatAssetFinancial(action) {
  try {
    const payload = yield call(Financial.updateAssetFinancial, action.payload);
    yield put({ type: UPDATE_ASSET_FINANCIAL_SUCCESS, payload });
    yield put({ type: GET_NOTIFICATIONS });

    showToast("s", "Financial updated successfully");
  } catch (error) {
    if (error.response.status === 401 || error.response.statsCode === 401) {
      handleFailedAuth();
      return;
    }
    const err = error.response.body;
    showToast("e", "Error While Updating Financial");
    yield put({ type: UPDATE_ASSET_FINANCIAL_FAILURE, err });
  }
}

export function* watchUpdateAssetFinancial() {
  yield takeLatest(UPDATE_ASSET_FINANCIAL, updatAssetFinancial);
}

function* deleteAssetFinancial(action) {
  try {
    const payload = yield call(Financial.deleteAssetFinancial, action.payload);
    yield put({ type: DELETE_ASSET_FINANCIAL_SUCCESS, payload });
    showToast("s", "Financial deleted successfully");
  } catch (error) {
    if (error.response.status === 401 || error.response.statsCode === 401) {
      handleFailedAuth();
      return;
    }
    const err = error.response.body;
    showToast("e", "Error While Updating Financial");
    yield put({ type: DELETE_ASSET_FINANCIAL_FAILURE, err });
  }
}

export function* watchDeleteAssetFinancial() {
  yield takeLatest(DELETE_ASSET_FINANCIAL, deleteAssetFinancial);
}
