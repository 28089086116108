import React, { PureComponent } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import TopbarProfile from "./TopbarProfile";
import TopbarNotification from "./TopbarNotification";
import TopbarNav from "./tobar_nav/TopbarNav";
import { SidebarProps } from "../../../shared/prop-types/ReducerProps";

import Logo from "../../../img/logo.png";
import clsx from "clsx";

class TopbarWithNavigation extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    sidebar: SidebarProps.isRequired,
    isShare: PropTypes.bool,
  };

  render() {
    const { changeMobileSidebarVisibility, isShare, sidebar } = this.props;
    return (
      <div className="app-header app-header--shadow app-header--opacity-bg font-size-xl">
        <div className="app-header--pane">
          <button
            className={clsx(
              "navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn",
              { "is-active": sidebar.show }
            )}
            onClick={changeMobileSidebarVisibility}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
          <div className="header-nav-logo">
            <Link className="nav-logo" to="/dashboard">
              <img
                className="header-logo-img"
                src={Logo}
                width="200px"
                alt="BetterCapital.US"
              />
            </Link>
          </div>
        </div>
        <div className="app-header--pane app-header--desktop">
          {!isShare && (
            <div className="header-nav-menu d-none d-lg-block">
              <TopbarNav />
            </div>
          )}
        </div>
        <div className="app-header--pane">
          <div className="header-nav-wrapper header-nav-wrapper-lg px-4 navbar-light">
            {!isShare && (
              <div className="header-nav-actions flex-grow-0 flex-lg-grow-1">
                <TopbarNotification new />
                {/*<TopbarMail new />*/}
                <TopbarProfile />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(TopbarWithNavigation);
