import {
  HIDE_ADD_ASSETS_MODAL,
  SHOW_ADD_ASSETS_MODAL,
} from "../actions/actionTypes";

const initialState = {
  show: false,
  saveMethod: () => {},
};

export const assetsAddModal = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ADD_ASSETS_MODAL:
      return {
        ...state,
        show: true,
        saveMethod: action.payload,
      };
    case HIDE_ADD_ASSETS_MODAL:
      return {
        ...state,
        show: false,
      };
    default:
      return state;
  }
};
