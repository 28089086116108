import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Card, Nav, NavItem, NavLink, Button } from "reactstrap";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

class ChatSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contacts: [],
      contactLoaded: false,
      queryParams: null,
      userId: null,
    };
  }

  render() {
    const { contacts } = this.props;
    if (!contacts) return <div />;

    return (
      <Card className="card-box mb-5">
        <div className="bg-composed-wrapper bg-primary">
          <div className="bg-composed-wrapper--content text-light p-4">
            <h4 className="mb-4">Chat</h4>
            <p className="mb-0 opacity-7">Chat with your contacts.</p>
          </div>
        </div>
        <PerfectScrollbar className="scroll-area-lg">
          <Nav pills className=" flex-column p-3">
            {contacts.map((contact) => {
              return (
                <NavItem key={contact.id}>
                  <NavLink
                    href="#/"
                    onClick={(e) => this.props.onClick(contact, "", e)}
                  >
                    <div className="align-box-row w-100">
                      <div className="mr-3">
                        <img alt="..." src={contact.picture} className="d-50" />
                      </div>
                      <div>
                        <div className="font-weight-bold text-primary d-block">
                          {contact.name}
                        </div>
                      </div>
                      <div className="ml-auto card-hover-indicator align-self-center">
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          className="font-size-xl"
                        />
                      </div>
                    </div>
                  </NavLink>
                </NavItem>
              );
            })}
          </Nav>
        </PerfectScrollbar>
        <div className="card-footer d-flex justify-content-center">
          <Button size="sm" color="primary">
            Add Contact
          </Button>
        </div>
      </Card>
    );
  }
}

export default ChatSidebar;
