import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import TopbarWithNavigation from "./topbar_with_navigation/TopbarWithNavigation";
import SidebarMobile from "./topbar_with_navigation/sidebar_mobile/SidebarMobile";
import FooterLayout from "../..//components/Footer";
import { getUserDetail } from "../../actions/auth/authAction";
import { getAccounts } from "../../actions/accounts/accountActions";

import {
  changeMobileSidebarVisibility,
  changeSidebarVisibility,
} from "../../actions/sidebar";
import { SidebarProps } from "../../shared/prop-types/ReducerProps";

class Layout extends Component {
  constructor() {
    super();
    this.state = {
      location: {},
      redirectTo: "/",
    };
  }

  componentDidMount() {
    // We want to load common state api's in the layout
    this.props.getUserDetail();
    this.props.getAccounts();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { isShare } = nextProps;
    if (!isShare && !nextProps.auth.isAuthenticated) {
      let a = window.location.pathname;
      const search = window.location.search;
      if (search) a += search;
      nextProps.history.push(`/login?redirectTo=${a}`);
      return {
        location: nextProps.location,
        redirectTo: a,
      };
    }
    return null;
  }

  static propTypes = {
    sidebar: SidebarProps.isRequired,
    isShare: PropTypes.bool,
  };

  changeSidebarVisibility = () => {
    this.props.changeSidebarVisibility();
  };

  changeMobileSidebarVisibility = () => {
    this.props.changeMobileSidebarVisibility();
  };

  render() {
    const { auth, sidebar } = this.props;
    let isShare = this.props.isShare;
    if (auth.isAuthenticated) {
      isShare = false;
    }
    return (
      <Fragment>
        {sidebar.show && (
          <div className="">
            <SidebarMobile
              sidebar={this.props.sidebar}
              changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
            />
          </div>
        )}
        <div className="app-main">
          <TopbarWithNavigation
            isShare={isShare}
            changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
            sidebar={this.props.sidebar}
          />
          {this.props.children}
          <FooterLayout />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebar: state.sidebar,
  userDetail: state.userDetail,
  auth: state.login,
  plaid: state.plaid,
});

const mapDispatchToProps = (dispatch) => ({
  getUserDetail: () => {
    getUserDetail(dispatch);
  },
  getAccounts: () => {
    getAccounts(dispatch);
  },
  changeSidebarVisibility: () => {
    dispatch(changeSidebarVisibility());
  },
  changeMobileSidebarVisibility: () => {
    dispatch(changeMobileSidebarVisibility());
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
