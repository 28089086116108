import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TopbarMenuLinks = ({ title, icon, iconColor, path, onClick }) => (
  <Link
    className="d-flex px-3 align-items-center dropdown-item rounded"
    to={path}
    onClick={onClick}
  >
    <div className="align-box-row w-100">
      <div className="mr-3">
        <div
          className={`${iconColor} text-center text-white d-40 rounded-circle`}
        >
          <FontAwesomeIcon icon={icon} />
        </div>
      </div>
      <div>
        <div className="font-weight-bold text-primary d-block">{title}</div>
      </div>
    </div>
  </Link>
);

TopbarMenuLinks.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  iconColor: PropTypes.string.isRequired,
};

export default TopbarMenuLinks;
