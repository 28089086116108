import React, { Component } from "react";

import clsx from "clsx";

import { connect } from "react-redux";

import Loki from "react-loki";
import AboutForm from "./steps/AboutForm";
import FAQsForm from "./steps/FAQsForm";
import PricingForm from "./steps/PricingForm";
import HighlightsForm from "./steps/HighlightsForm";
import showToast from "../../../helpers/toastNotification";

import { uploadFile } from "../../../actions/resource";
import {
  signupPartner,
  updatePartner,
  getPartner,
} from "../../../actions/partners";

class PartnerSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      saving: false,
      loadingPartner: false,
      partnerInfo: null,
      uploadingFile: false,
      uploadingVideo: false,
      partner: {},
      emptyPartnerData: {
        name: "",
        bio: "",
        videoUrl: "",
        videoId: "",
        imageUrl: "",
        imageId: "",
        imageName: "",
        category: "",
        tags: [],
        highlights: [{ icon: "", title: "", subtitle: "" }],
        plans: [
          {
            planName: "",
            planPrice: "",
            planDescription: "",
            planUrl: "",
          },
        ],
        questions: [{ question: "", answer: "" }],
      },
    };
  }
  componentDidMount() {
    const {
      match: {
        params: { slug },
      },
    } = this.props;

    if (slug)
      this.setState({ loadingPartner: true }, () => {
        if (slug) this.props.getPartner(slug);
      });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      match: {
        params: { slug },
      },
    } = nextProps;

    if (
      prevState.loadingPartner &&
      nextProps.partnerProp.getPartnerSuccess === true &&
      nextProps.partnerProp.partnerInfo
    ) {
      // This means the current user is the owner of the partner profile
      if (
        nextProps.partnerProp.partnerInfo.user === nextProps.loginProp.user.id
      ) {
        return {
          loadingPartner: false,
          partner:
            nextProps.partnerProp.partnerInfo || prevState.emptyPartnerData,
        };
      } else {
        showToast("w", "You can't edit someone else partner profile!!");
        nextProps.history.push(`/dashboard`);
        return null;
      }
    } else if (
      !prevState.loadingPartner &&
      !slug &&
      Object.keys(prevState.partner).length === 0
    ) {
      return {
        loadingPartner: false,
        partner: prevState.emptyPartnerData,
      };
    } else if (prevState.saving) {
      const {
        partnerProp,
        match: {
          params: { slug },
        },
      } = nextProps;
      if (slug && partnerProp.updatePartnerSuccess)
        nextProps.history.push(`/partners/${slug}`);
      else if (partnerProp.signUpPartnerSuccess) {
        nextProps.history.push(`/partners/${partnerProp.partner.slug}`);
      }
      return null;
    } else return null;
  }

  customSteps = [
    {
      label: "About",
      number: "1",
      description: "Tell us more about your business",
    },
    {
      label: "Highlights",
      number: "2",
      description: "What differentiates you from others",
    },
    {
      label: "Pricing",
      number: "3",
      description: "Different paid services you offer",
    },
    {
      label: "FAQs",
      number: "4",
      description: "Common questions you get from consumers",
    },
  ];

  _mergeValues = (values) => {
    this.setState({
      partner: {
        ...this.state.partner,
        ...values,
      },
    });
  };

  _finishWizard = (values) => {
    const {
      match: {
        params: { slug },
      },
    } = this.props;
    let partnerValues = this.state.partner;
    Object.assign(partnerValues, values);
    this.setState({ saving: true }, () => {
      if (slug) {
        this.props.updatePartner(partnerValues);
      } else this.props.signupPartner(partnerValues);
    });
  };

  _customStepRenderer = ({ currentStep }) => {
    const steps = this.customSteps.map((step, index) => {
      const isActive = currentStep === index + 1;
      return (
        <li key={index} className={clsx("card-box", { current: isActive })}>
          <a href="#/" onClick={(e) => e.preventDefault()}>
            <div className="step-indicator">
              <div className="d-flex">
                <div className="font-size-lg d-44 rounded-sm mr-2 stepper-wrapper text-center">
                  <span className="font-weight-bold">{step.number}</span>
                </div>
                <div>
                  <div className="font-weight-bold">{step.label}</div>
                  <small className="text-black-50">{step.description}</small>
                </div>
              </div>
            </div>
          </a>
        </li>
      );
    });

    return (
      <div className="horizontal">
        <ul className="steps-indicator">{steps}</ul>
      </div>
    );
  };

  _customComponentRenderer = (props) => {
    switch (props.currentStep) {
      case 2:
        return <HighlightsForm partner={this.state.partner} {...props} />;
      case 3:
        return <PricingForm partner={this.state.partner} {...props} />;
      case 4:
        return <FAQsForm partner={this.state.partner} {...props} />;
      case 1:
      default:
        return <AboutForm partner={this.state.partner} {...props} />;
    }
  };
  render() {
    if (
      this.state.loadingPartner ||
      Object.keys(this.state.partner).length === 0
    ) {
      return <div />;
    }

    return (
      <div className="wizard-alternate-2 horizontal p-5">
        <Loki
          steps={this.customSteps}
          renderSteps={this._customStepRenderer}
          renderComponents={this._customComponentRenderer}
          onNext={this._mergeValues.bind(this)}
          // onBack={this._mergeValues.bind(this)}
          onFinish={this._finishWizard.bind(this)}
          noActions
          backLabel={"Previous"}
          nextLabel={"Next"}
          finishLabel={"Submit"}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  uploadProp: state.upload,
  partnerProp: state.partners,
  loginProp: state.login,
});

const mapDispatchToProps = (dispatch) => ({
  uploadFile: (data) => uploadFile(data, dispatch),
  signupPartner: (data) => signupPartner(data, dispatch),
  updatePartner: (data) => updatePartner(data, dispatch),

  getPartner: (slug) => getPartner(slug, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(PartnerSignup);
