import { put, call, takeEvery, takeLatest } from "redux-saga/effects";
import { Importer } from "../api/agent";
import showToast from "../helpers/toastNotification";

import {
  ADD_IMPORTER,
  ADD_IMPORTER_SUCCESS,
  ADD_IMPORTER_FAILURE,
  GET_IMPORTERS,
  GET_IMPORTERS_SUCCESS,
  GET_IMPORTERS_FAILURE,
  DELETE_IMPORTER,
  DELETE_IMPORTER_SUCCESS,
  DELETE_IMPORTER_FAILURE,
  IMPORTER_PARSE_FILE,
  IMPORTER_PARSE_FILE_SUCCESS,
  IMPORTER_PARSE_FILE_FAILURE,
} from "../actions/actionTypes";

function* getImporters(action) {
  try {
    const payload = yield call(Importer.getImporters, action.payload);
    yield put({ type: GET_IMPORTERS_SUCCESS, payload });
  } catch (error) {
    const err = error.response.body;
    yield put({ type: GET_IMPORTERS_FAILURE, err });
  }
}
export function* watchGetImporters() {
  yield takeLatest(GET_IMPORTERS, getImporters);
}

export function* addImporter(action) {
  try {
    const payload = yield call(Importer.addImporter, action.payload);
    yield put({ type: ADD_IMPORTER_SUCCESS, payload });
    showToast("s", "Importer Added Successfully");
  } catch (error) {
    const err = error.response.body;
    showToast("e", err.message || "Unable To Add Importer");
    yield put({ type: ADD_IMPORTER_FAILURE, err });
  }
}
export function* watchAddImporter() {
  yield takeEvery(ADD_IMPORTER, addImporter);
}

function* deleteImporter(action) {
  try {
    const payload = yield call(Importer.deleteImporter, action.payload);
    showToast("s", "Importer Deleted Successfully");
    yield put({ type: DELETE_IMPORTER_SUCCESS, payload });
  } catch (error) {
    const err = error.response.body;
    showToast("e", err.message || "Unable To Delete Importer");
    yield put({ type: DELETE_IMPORTER_FAILURE, err });
  }
}
export function* watchDeleteImporter() {
  yield takeLatest(DELETE_IMPORTER, deleteImporter);
}

function* importerParseFile(action) {
  try {
    const payload = yield call(Importer.parseFile, action.payload);
    yield put({ type: IMPORTER_PARSE_FILE_SUCCESS, payload });
  } catch (err) {
    const errResp = err.response.body;
    yield put({ type: IMPORTER_PARSE_FILE_FAILURE, errResp });
  }
}

export function* watchImporterParseFile() {
  yield takeEvery(IMPORTER_PARSE_FILE, importerParseFile);
}
