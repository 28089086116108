import {
  GET_RESOURCES,
  RESERVE_RESOURCE,
  POST_RESOURCE,
  UPLOAD_FILE,
  GET_MY_RESOURCES,
  GET_VIEW_RESOURCE,
  UPLOAD_FILES,
} from "../actionTypes";
export const getResources = (query, dispatch) => {
  dispatch({
    type: GET_RESOURCES,
    payload: query,
  });
};

export const getViewResource = (slug, dispatch) => {
  dispatch({
    type: GET_VIEW_RESOURCE,
    payload: slug,
  });
};

export const reserveResource = (id, dispatch) => {
  dispatch({
    type: RESERVE_RESOURCE,
    payload: id,
  });
};

export const getResourcesStats = (query, dispatch) => {
  dispatch({
    type: "GET_RESOURCE_STATS",
    payload: query,
  });
};

export const getMyResources = (query, dispatch) => {
  dispatch({
    type: GET_MY_RESOURCES,
    payload: query,
  });
};

export const getMyResourcesStats = (query, dispatch) => {
  dispatch({
    type: "GET_MY_RESOURCE_STATS",
    payload: query,
  });
};

export const postResource = (data, dispatch) => {
  dispatch({
    type: POST_RESOURCE,
    payload: data,
  });
};

export const uploadFile = (resource, dispatch) => {
  dispatch({
    type: UPLOAD_FILE,
    payload: resource,
  });
};

export const uploadFiles = (resource, dispatch) => {
  dispatch({
    type: UPLOAD_FILES,
    payload: resource,
  });
};
