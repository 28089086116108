import React from "react";
import { CardBody, Card } from "reactstrap";
import { connect } from "react-redux";

import FullCalender from "../../shared/components/FullCalender";
import AddReminder from "./AddReminder";
import ViewReminder from "./ViewReminder";

import { getRemindersList } from "../../actions/reminder";
class Reminders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showReminderModal: false,
      viewReminderModal: false,

      selectedData: null,

      toViewEvent: null,
    };
  }

  componentDidMount() {
    this.props.getReminders();
  }

  toggleModal = (data) => {
    this.setState((prevState) => ({
      showReminderModal: !prevState.showReminderModal,
      selectedData: data,
    }));
  };

  toggleViewReminderModal = (event) => {
    this.setState((prevState) => ({
      viewReminderModal: !prevState.viewReminderModal,
      toViewEvent: event,
    }));
  };

  render() {
    const { reminders } = this.props.reminders;
    return (
      <main className="container-fluid p-5">
        <Card>
          <CardBody className="card_box">
            <div className="pb-3">
              <h4 className="float-left">{"Reminders"}</h4>
              <div className="float-right">
                <button
                  onClick={() => this.toggleModal(null)}
                  className="btn btn-primary px-4"
                >
                  <i className="fas fa-bell" /> Create Reminder
                </button>
              </div>
            </div>
            <FullCalender
              events={reminders}
              handleDateClick={this.toggleModal}
              handleEventClick={this.toggleViewReminderModal}
            />
            <AddReminder
              modalOpen={this.state.showReminderModal}
              toggleModal={this.toggleModal}
              selectedData={this.state.selectedData}
            />
            <ViewReminder
              modalOpen={this.state.viewReminderModal}
              toggleModal={this.toggleViewReminderModal}
              selectedData={this.state.toViewEvent}
            />
          </CardBody>
        </Card>
      </main>
    );
  }
}
const mapStateToProps = (state) => ({
  reminders: state.reminders,
});
const mapDispatchToProps = (dispatch) => ({
  getReminders: () => getRemindersList(dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Reminders);
