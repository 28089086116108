import { put, call, takeLatest } from "redux-saga/effects";
import { Contact } from "../api/agent";
import showToast from "../helpers/toastNotification";

import {
  REQUEST_PERMISSION,
  REQUEST_PERMISSION_SUCCESS,
  REQUEST_PERMISSION_FAILURE,
} from "../actions/actionTypes";

function* requestPermission(action) {
  try {
    const { slug, personalNote } = action.payload;
    const payload = yield call(Contact.requestPermission, slug, personalNote);
    yield put({ type: REQUEST_PERMISSION_SUCCESS, payload });
    showToast("s", "Permission request sent");
  } catch (error) {
    const err = error.response.body;
    showToast("e", err.message || "Unable to send permission request");
    yield put({ type: REQUEST_PERMISSION_FAILURE, err });
  }
}
export function* watchRequestPermission() {
  yield takeLatest(REQUEST_PERMISSION, requestPermission);
}
