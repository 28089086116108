import moment from "moment";

const categoryNameOptions = [
  {
    label: "Admin & Other",
    value: "Admin Other",
  },
  {
    label: "Legal & Professional",
    value: "Legal & Professional",
  },
  {
    label: "Management Fees",
    value: "Management Fees",
  },
  {
    label: "Repairs & Maintenance",
    value: "Repairs & Maintenance",
  },
  {
    label: "Utilities",
    value: "Utilities",
  },
];

const categoryOptions = [
  {
    label: "Monthly Income",
    options: [
      { label: "Rent", value: "rent", type: "income" },
      { label: "Laundry", value: "laundry", type: "income" },
      { label: "Storage", value: "storage", type: "income" },
      { label: "Parking", value: "parking", type: "income" },
      { label: "Pet Fee", value: "pet_fee", type: "income" },
    ],
  },
  {
    label: "Other Income",
    options: [
      { label: "Deposit", value: "deposit", type: "income" },
      { label: "Pet Deposit", value: "pet_deposit", type: "income" },
      { label: "Fee (Application)", value: "application_fee", type: "income" },
      { label: "Fee (Other)", value: "other_fee", type: "income" },
      {
        label: "Investment Payoff",
        value: "investment_payoff",
        type: "income",
      },
    ],
  },
  {
    label: "Monthly Expenses",
    options: [
      {
        label: "Cleaning/Maintenance",
        value: "cleaning_maintenance",
        type: "expense",
      },
      {
        label: "Yard/Snow removal",
        value: "yard_snow_removal",
        type: "expense",
      },
      { label: "Garbage", value: "garbage", type: "expense" },
      { label: "Gas", value: "gas", type: "expense" },
      { label: "Electricity", value: "electricity", type: "expense" },
      { label: "Water/Sewer", value: "water_sewer", type: "expense" },
      { label: "HOA Dues", value: "hoa_fee", type: "expense" },
    ],
  },
  {
    label: "Operating Expenses",
    options: [
      { label: "Advertising", value: "advertising", type: "expense" },
      {
        label: "Technology Licenses",
        value: "technology_licenses",
        type: "expense",
      },
      { label: "Management Fees", value: "management_fee", type: "expense" },
      { label: "Auto Travel", value: "auto_travel", type: "expense" },
      { label: "Telephone", value: "telephone", type: "expense" },
      {
        label: "Professional Services",
        value: "professional_services",
        type: "expense",
      },
      { label: "Office Supplies", value: "offfice_supplies", type: "expense" },
      {
        label: "Wages and Salaries",
        value: "wages_and_salaries",
        type: "expense",
      },
      { label: "Insurance", value: "insurance", type: "expense" },
      { label: "Taxes", value: "taxes", type: "expense" },
      { label: "Licenses", value: "licenses", type: "expense" },
    ],
  },
  {
    label: "Other Expenses",
    options: [
      {
        label: "Real Estate Taxes",
        value: "real_estate_taxes",
        type: "expense",
      },
      { label: "Other taxes", value: "other_taxes", type: "expense" },
      {
        label: "Mortgage Interest",
        value: "mortgage_interest",
        type: "expense",
      },
      { label: "Other Interest", value: "other_interest", type: "expense" },
      { label: "Pest Control", value: "pest_control", type: "expense" },
      {
        label: "Equipment Purchased",
        value: "equipment_purchased",
        type: "expense",
      },
      {
        label: "Principal Paydown",
        value: "principal_paydown",
        type: "expense",
      },
    ],
  },
  {
    label: "Repairs",
    options: [
      { label: "Painting", value: "painting", type: "expense" },
      { label: "Plumbing", value: "plumbing", type: "expense" },
      { label: "Electrical", value: "electrical", type: "expense" },
      { label: "Appliances", value: "appliances", type: "expense" },
    ],
  },
];

const dateFilters = [
  {
    type: "This Year",
    name: "This Year",
    _id: "this_year",
    fromDate: moment().startOf("year"),
    toDate: moment().endOf("year"),
  },
  {
    type: "This Month",
    name: "This Month",
    _id: "this_month",
    fromDate: moment().startOf("month"),
    toDate: moment().endOf("month"),
  },
  {
    type: "Last Month",
    name: "Last Month",
    _id: "last_month",
    fromDate: moment().subtract(1, "month").startOf("month"),
    toDate: moment().subtract(1, "month").endOf("month"),
  },
  {
    type: "Last Year",
    name: "Last Year",
    _id: "last_year",
    fromDate: moment().subtract(1, "year").startOf("year"),
    toDate: moment().subtract(1, "year").endOf("year"),
  },
  {
    type: "Last 6 Months",
    name: "Last 6 Months",
    _id: "last_6_months",
    fromDate: moment().subtract(6, "month").startOf("month"),
    toDate: moment().endOf("month"),
  },
  {
    type: "Last 12 Months",
    name: "Last 12 Months",
    _id: "last_12_months",
    fromDate: moment().subtract(12, "month").startOf("month"),
    toDate: moment().endOf("month"),
  },
];

const reportFilters = [
  {
    type: "Last Year",
    name: "Last Year",
    _id: "last_year",
    fromDate: moment().subtract(1, "year").startOf("year"),
    toDate: moment().subtract(1, "year").endOf("year"),
  },
  {
    type: "This Year",
    name: "This Year",
    _id: "this_year",
    fromDate: moment().startOf("year"),
    toDate: moment().endOf("year"),
  },
  {
    type: "Last 6 Months",
    name: "Last 6 Months",
    _id: "last_6_months",
    fromDate: moment().subtract(6, "month").startOf("month"),
    toDate: moment().endOf("month"),
  },
  {
    type: "Last 12 Months",
    name: "Last 12 Months",
    _id: "last_12_months",
    fromDate: moment().subtract(12, "month").startOf("month"),
    toDate: moment().endOf("month"),
  },
];

const lastMonthStart = moment()
  .subtract(1, "months")
  .startOf("month")
  .format("MM/DD/YYYY");
const lastMonthEnd = moment()
  .subtract(1, "months")
  .endOf("month")
  .format("MM/DD/YYYY");

export {
  categoryNameOptions,
  dateFilters,
  categoryOptions,
  reportFilters,
  lastMonthStart,
  lastMonthEnd,
};
