import React, { PureComponent } from "react";
import { getIn } from "formik";
import clsx from "clsx";

class Textarea extends PureComponent {
  render() {
    const {
      field,
      form: { touched, errors },
      props,
      preserveMultiLine,
      placeholder,
      rows,
    } = this.props;
    const touch = getIn(touched, field.name);
    const errorMessage = getIn(errors, field.name);
    return (
      <div>
        <textarea
          {...field}
          {...props}
          className={clsx("form-control", {
            "is-invalid": touch && errorMessage,
          })}
          style={preserveMultiLine ? { whiteSpace: "pre-line" } : {}}
          placeholder={placeholder}
          rows={rows || 4}
        />
        <div className="invalid-feedback">{errorMessage}</div>
      </div>
    );
  }
}

export default Textarea;
