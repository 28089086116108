import {
  GET_INTEGRATIONS,
  GET_INTEGRATIONS_SUCCESS,
  GET_INTEGRATIONS_FAILURE,
  ADD_INTEGRATION,
  ADD_INTEGRATION_SUCCESS,
  ADD_INTEGRATION_FAILURE,
  UPDATE_INTEGRATION,
  UPDATE_INTEGRATION_SUCCESS,
  UPDATE_INTEGRATION_FAILURE,
  DELETE_INTEGRATION,
  DELETE_INTEGRATION_SUCCESS,
  DELETE_INTEGRATION_FAILURE,
  SYNC_INTEGRATION,
  SYNC_INTEGRATION_SUCCESS,
  SYNC_INTEGRATION_FAILURE,
} from "../actions/actionTypes";

import {
  setLoadingState,
  setSuccessResponse,
  setErrorResponse,
} from "./reducerUtils";

const initialState = {
  data: [],
  state: "INIT",
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_INTEGRATIONS:
      return setLoadingState(state, "", "GET");
    case GET_INTEGRATIONS_SUCCESS:
      return setSuccessResponse(state, action, "", "GET");
    case GET_INTEGRATIONS_FAILURE:
      return setErrorResponse(state, action.err, "", "GET");
    case ADD_INTEGRATION:
      return setLoadingState(state, "", "ADD");
    case ADD_INTEGRATION_SUCCESS:
      return setSuccessResponse(state, action, "", "ADD");
    case ADD_INTEGRATION_FAILURE:
      return setErrorResponse(state, action.err, "", "ADD");
    case UPDATE_INTEGRATION:
      return setLoadingState(state, "", "UPDATE");
    case UPDATE_INTEGRATION_SUCCESS:
      return setSuccessResponse(state, action, "", "UPDATE");
    case UPDATE_INTEGRATION_FAILURE:
      return setErrorResponse(state, action.err, "", "UPDATE");
    case DELETE_INTEGRATION:
      return setLoadingState(state, "", "DELETE");
    case DELETE_INTEGRATION_SUCCESS:
      return setSuccessResponse(state, action, "", "DELETE");
    case DELETE_INTEGRATION_FAILURE:
      return setErrorResponse(state, action.err, "", "DELETE");
    case SYNC_INTEGRATION:
      return setLoadingState(state, "", "SYNC");
    case SYNC_INTEGRATION_SUCCESS:
      return setSuccessResponse(state, action, "", "SYNC");
    case SYNC_INTEGRATION_FAILURE:
      return setErrorResponse(state, action.err, "", "SYNC");
    default:
      return state;
  }
}
