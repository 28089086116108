import {
  GET_REMINDERS_SUCCESS,
  GET_REMINDERS_FAILURE,
  GET_REMINDERS,
  ADD_REMINDER,
  ADD_REMINDER_SUCCESS,
  ADD_REMINDER_FAILURE,
  UPDATE_REMINDER,
  UPDATE_REMINDER_SUCCESS,
  UPDATE_REMINDER_FAILURE,
  GET_NOTIFICATIONS,
} from "../actions/actionTypes";

import handleFailedAuth from "../helpers/handleExpiredToken";

import { Reminder } from "../api/agent";
import showToast from "../helpers/toastNotification";

import { put, call, takeEvery } from "redux-saga/effects";

function* getReminderList(action) {
  try {
    const payload = yield call(Reminder.getReminders);
    yield put({ type: GET_REMINDERS_SUCCESS, payload });
  } catch (error) {
    if (error.response.status === 401 || error.response.statsCode === 401) {
      handleFailedAuth();
      return;
    }
    const errResp = error.response.body;
    yield put({ type: GET_REMINDERS_FAILURE, errResp });
  }
}

export function* watchGetReminderList() {
  yield takeEvery(GET_REMINDERS, getReminderList);
}

function* updateReminder(action) {
  try {
    const payload = yield call(Reminder.updateReminder, action.payload);
    yield put({ type: UPDATE_REMINDER_SUCCESS, payload });
    showToast("s", "Reminder Updated");
    yield put({ type: GET_NOTIFICATIONS });
  } catch (error) {
    if (error.response.status === 401 || error.response.statsCode === 401) {
      handleFailedAuth();
      return;
    }
    const errResp = error.response.body;
    showToast("e", errResp || "Error while updating");

    yield put({ type: UPDATE_REMINDER_FAILURE, errResp });
  }
}

export function* watchUpdateReminder() {
  yield takeEvery(UPDATE_REMINDER, updateReminder);
}

function* addReminder(action) {
  try {
    const payload = yield call(Reminder.addReminder, action.payload);
    yield put({ type: ADD_REMINDER_SUCCESS, payload });
    showToast("s", "Reminder Added");

    yield put({ type: GET_NOTIFICATIONS });
  } catch (error) {
    if (error.response.status === 401 || error.response.statsCode === 401) {
      handleFailedAuth();
      return;
    }
    const errResp = error.response.body;
    showToast("e", errResp || "Unable To Add Reminder");
    yield put({ type: ADD_REMINDER_FAILURE, errResp });
  }
}

export function* watchAddReminder() {
  yield takeEvery(ADD_REMINDER, addReminder);
}
