/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { getPartner } from "../../../actions/partners";
import LoadingIndicator from "../../../shared/components/LoadingIndicator";
import Image from "react-graceful-image";
import clsx from "clsx";

import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { PartnerLinks } from "./PartnerLinks";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";

import {
  Row,
  Col,
  CardBody,
  Card,
  CardHeader,
  Badge,
  Button,
  Collapse,
} from "reactstrap";

class PartnerProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingPartner: true,
      selectedQuestion: 0,
      partnerInfo: null,

      resourceLoading: true,
      resources: [],

      userDealsLoading: true,
      deals: [],

      showPostResource: false,

      showEditProfile: false,
    };
  }

  togglePostResource = () => {
    this.setState((nextState) => ({
      showPostResource: !nextState.showPostResource,
    }));
  };

  selectQuestion = (idx) => {
    this.setState((nextState) => ({
      selectedQuestion: idx,
    }));
  };

  toggleShowPostDeal = () => {
    this.setState((prevState) => ({ showPostDeal: !prevState.showPostDeal }));
  };

  editPartnerProfile = () => {
    const {
      match: {
        params: { slug },
      },
    } = this.props;

    this.props.history.push(`/partners/${slug}/edit`);
  };

  messagePartnerProfile = () => {
    const {
      match: {
        params: { slug },
      },
    } = this.props;
    this.props.history.push(`/chats/${slug}`);
  };

  componentDidMount() {
    const {
      match: {
        params: { slug },
      },
    } = this.props;

    this.props.getPartner(slug);
  }

  componentWillUnmount() {
    this.setState({ partnerInfo: {} });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { loadingPartner } = prevState;
    const { partnerProp } = nextProps;
    if (loadingPartner) {
      if (partnerProp.getPartnerSuccess === true)
        return {
          loadingPartner: false,
          partnerInfo: partnerProp.partnerInfo,
        };
      else if (partnerProp.getPartnerSuccess === false)
        return { loadingPartner: false, partnerInfo: null };
    }
  }

  renderHighlights = (highlights) => {
    return highlights.map((highlight, index) => {
      return (
        <Col xl="4" key={index}>
          <Card
            className="card-box-hover-rise mb-5"
            style={{ minHeight: "160px" }}
          >
            <CardBody>
              <h3 className="heading-6 mt-4 mb-3 font-weight-bold">
                {highlight.title}
              </h3>
              <p className="card-text mb-3">{highlight.subtitle}</p>
            </CardBody>
          </Card>
        </Col>
      );
    });
  };

  renderFAQs = (questions) => {
    return questions.map((question, index) => {
      return (
        <Card
          key={`partner-question-card-${index}`}
          className={clsx("card-box w-100", {
            "panel-open": this.state.selectedQuestion === index,
          })}
        >
          <Card>
            <CardHeader>
              <div className="panel-title">
                <div className="accordion-toggle">
                  <Button
                    color="link"
                    size="lg"
                    className="d-flex align-items-center justify-content-between"
                    onClick={() => this.selectQuestion(index)}
                    aria-expanded={this.state.selectedQuestion === index}
                  >
                    <span>{question.question}</span>
                    <FontAwesomeIcon
                      icon={faAngleUp}
                      className="font-size-xl accordion-icon"
                    />
                  </Button>
                </div>
              </div>
            </CardHeader>
            <Collapse isOpen={this.state.selectedQuestion === index}>
              <div className="p-4">{question.answer}</div>
            </Collapse>
          </Card>
        </Card>
      );
    });
  };

  isProfileOwner = () => {
    const {
      match: {
        params: { slug },
      },
      auth,
    } = this.props;

    return slug === auth.user.partnerSlug;
  };
  renderOwnerActions = () => {
    if (!this.isProfileOwner()) {
      return <div />;
    }

    return (
      <div className="card-tr-actions">
        <Button
          tag="a"
          onClick={(e) => this.editPartnerProfile()}
          size="lg"
          color="secondary"
          className="d-block d-sm-inline-block"
        >
          <span className="btn-wrapper--icon">
            <FontAwesomeIcon icon={faEdit} />
          </span>
          <span className="btn-wrapper--label">Edit</span>
        </Button>
        <div className="mr-2 d-inline-block" />
      </div>
    );
  };

  render() {
    const { partnerInfo } = this.state;
    if (this.state.loadingPartner) {
      return <LoadingIndicator />;
    } else if (!this.state.loadingPartner && this.state.partnerInfo) {
      return (
        <Row className="p-5">
          <Col xl="8">
            <Card className="card-box mb-5 px-4 pt-4">
              {this.renderOwnerActions()}

              <div className="py-3 py-xl-5">
                <div className="container py-3 py-xl-5">
                  <Row>
                    <Col
                      xl="6"
                      className="d-flex align-items-center text-center text-xl-left"
                    >
                      <div className="mb-5 mb-xl-0">
                        <div className="mb-4">
                          {partnerInfo.tags &&
                            partnerInfo.tags.map((tag, index) => {
                              return (
                                <Badge
                                  pill
                                  color="warning"
                                  className="px-4 h-auto py-1 ml-1"
                                  key={`badge-${index}`}
                                >
                                  {tag}
                                </Badge>
                              );
                            })}
                          <h1 className="display-3 mt-3 font-weight-bold">
                            {partnerInfo.name}
                          </h1>
                          <div className="divider border-2 mx-auto mx-xl-0 my-3 border-light" />
                          <p className="font-size-lg text-black-50">
                            {partnerInfo.bio}
                          </p>
                        </div>
                        {<PartnerLinks />}
                      </div>
                    </Col>
                    <Col xl="6">
                      <Card className="shadow-xxl p-3">
                        <Card
                          className="card-box shadow-none"
                          style={{ height: 350 }}
                        >
                          <Image
                            src={partnerInfo.imageUrl}
                            height="350px"
                            alt="..."
                            style={{ borderRadius: "0.65rem" }}
                          />{" "}
                        </Card>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="pt-5">
                    {partnerInfo.highlights &&
                      this.renderHighlights(partnerInfo.highlights)}
                  </Row>

                  <Row className="accordion p-4">
                    <h3 className="display-4 my-3 font-weight-bold">FAQ's</h3>
                    {partnerInfo.questions &&
                      this.renderFAQs(partnerInfo.questions)}
                  </Row>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  partnerProp: state.partners,
  auth: state.login,
});

const mapDispatchToProps = (dispatch) => ({
  getPartner: (slug) => getPartner(slug, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(PartnerProfile);
