import { put, call, takeLatest } from "redux-saga/effects";
import { Invite } from "../api/agent";
import showToast from "../helpers/toastNotification";

import {
  INVITE_USER_FAILURE,
  INVITE_USER,
  INVITE_USER_SUCCESS,
} from "../actions/actionTypes";

function* sendInvite(action) {
  try {
    const { slug, personalNote } = action.payload;
    const payload = yield call(Invite.sendInvite, slug, personalNote);
    yield put({ type: INVITE_USER_SUCCESS, payload });
    showToast("s", "Invitation Sent Successfully");
  } catch (error) {
    const err = error.response.body;
    showToast("e", err.message || "Unable To Send Invitation");
    yield put({ type: INVITE_USER_FAILURE, err });
  }
}
export function* watchSendInvite() {
  yield takeLatest(INVITE_USER, sendInvite);
}
