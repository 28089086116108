import {
  GET_IMPORTERS,
  GET_IMPORTERS_SUCCESS,
  GET_IMPORTERS_FAILURE,
  ADD_IMPORTER,
  ADD_IMPORTER_SUCCESS,
  ADD_IMPORTER_FAILURE,
  DELETE_IMPORTER,
  DELETE_IMPORTER_SUCCESS,
  DELETE_IMPORTER_FAILURE,
} from "../actions/actionTypes";

import {
  setLoadingState,
  setSuccessResponse,
  setErrorResponse,
} from "./reducerUtils";

const initialState = {
  data: [],
  state: "INIT",
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_IMPORTERS:
      return setLoadingState(state, "", "GET");
    case GET_IMPORTERS_SUCCESS:
      return setSuccessResponse(state, action, "", "GET");
    case GET_IMPORTERS_FAILURE:
      return setErrorResponse(state, action.err, "", "GET");
    case ADD_IMPORTER:
      return setLoadingState(state, "", "ADD");
    case ADD_IMPORTER_SUCCESS:
      return setSuccessResponse(state, action, "", "ADD");
    case ADD_IMPORTER_FAILURE:
      return setErrorResponse(state, action.err, "", "ADD");
    case DELETE_IMPORTER:
      return setLoadingState(state, "", "DELETE");
    case DELETE_IMPORTER_SUCCESS:
      return setSuccessResponse(state, action, "", "DELETE");
    case DELETE_IMPORTER_FAILURE:
      return setErrorResponse(state, action.err, "", "DELETE");

    default:
      return state;
  }
}
