import React from "react";
import socketIoObject from "./socketinit";
import { connect } from "react-redux";

import { Input, UncontrolledTooltip, Button } from "reactstrap";
import FileInput from "../../../shared/components/form/FileInput";
import { uploadFile } from "../../../actions/resource";

class ChatField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: props.defaultMessage,
      uploadingImage: false,
    };
  }
  sendMessage = (e) => {
    e.preventDefault();
    const {
      currentContact,
      auth: { user },
    } = this.props;
    const { message } = this.state;
    if (message)
      socketIoObject.emit("SEND_MESSAGE", {
        auth: user,
        payload: { message, receiver: currentContact.id },
      });
  };

  sendAttachment = (fileData) => {
    const {
      currentContact,
      auth: { user },
    } = this.props;
    socketIoObject.emit("SEND_MESSAGE", {
      auth: user,
      payload: {
        message_type: "File",
        message: "",
        file: fileData,
        receiver: currentContact.id,
      },
    });
  };

  componentDidMount() {
    socketIoObject.on("MESSAGE_SENT", () => {
      this.setState({ message: "" });
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ message: nextProps.defaultMessage });

    if (this.state.uploadingImage && nextProps !== this.props) {
      const { uploadProp } = nextProps;
      if (uploadProp.uploadFileSuccess === true) {
        this.setState({ uploadingImage: false }, () => {
          this.sendAttachment(uploadProp);
        });
      } else if (uploadProp.uploadFileSuccess === false) {
      }
    }
  }

  handleMessage = (e) => {
    this.setState({ message: e.value });
  };

  handleFileSelect = (files) => {
    const {
      currentContact,
      auth: { user },
    } = this.props;
    const { message } = this.state;
    socketIoObject.emit("UPLOAD_FILE", {
      auth: user,
      payload: { message, receiver: currentContact.id, file: files },
    });
  };

  handleUploadFile = (files) => {
    this.setState({ uploadingImage: true }, () => {
      this.props.uploadFile(files);
    });
  };
  handleFileError = () => {
    console.log("error");
  };

  render() {
    const { message } = this.state;

    return (
      <div className="app-content--inner__footer rounded-bottom bg-white p-4 border-top">
        <form className="chat__form" onSubmit={this.sendMessage}>
          <div>
            <Input
              name="message"
              value={message}
              placeholder="Write your message and hit enter to send..."
              onChange={({ target }) => this.handleMessage(target)}
            />
          </div>
          <div className="d-flex justify-content-between mt-3">
            <div className="d-flex align-items-center">
              <FileInput
                onSuccess={(files) => {
                  this.handleUploadFile(files);
                }}
                onError={this.handleFileError}
                extensions={["xlsx", "xls", "pdf", "csv", "png", "jpg", "jpeg"]}
                multiple={false}
                disabled={this.state.uploadingImage}
                id={"file"}
                className={"hide"}
              />
              <UncontrolledTooltip target="file">
                Upload Documents
              </UncontrolledTooltip>
            </div>
            <Button type="submit" color="primary">
              Send
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    auth: state.login,
    uploadProp: state.upload,
  }),
  (dispatch) => ({
    uploadFile: (data) => uploadFile(data, dispatch),
  })
)(ChatField);
