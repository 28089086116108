import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import Layout from "./components/Layout/index";

import Login from "./components/Login/Login";
import Signup from "./components/Signup/Signup";

import Activateuser from "./components/Activation";
import ResetPassword from "./components/ResetPassword";
import NotFound from "./components/NotFound/NotFound";
import Chat from "./components/Chat";
import Reminders from "./components/Reminders";

import PartnerProfile from "./components/Partner/Profile";
import PartnerSignup from "./components/Partner/Signup";

import AccountInfo from "./pages/Settings/AccountInfo";
import LoadingIndicator from "./shared/components/LoadingIndicator";

const Contacts = lazy(() => import("./pages/Contacts"));
const ContactDetails = lazy(() => import("./pages/ContactDetails"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const AssetDetails = lazy(() => import("./pages/AssetDetails"));
const Integrations = lazy(() => import("./pages/Integrations"));

const Pages = (props) => {
  return (
    <Suspense
      fallback={
        <div className={"d-flex align-items-center justify-content-center"}>
          <LoadingIndicator />
        </div>
      }
    >
      <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route path="/partners/:slug/edit" component={PartnerSignup} />
        <Route path="/partners/signup" component={PartnerSignup} />
        <Route path="/partners/:slug" component={PartnerProfile} />
        <Route exact path="/chats/" component={Chat} />
        <Route exact path="/chats/:userId" component={Chat} />
        <Route path="/reminders" component={Reminders} />
        <Route
          path="/contacts/:slug/assets/:assetSlug"
          component={AssetDetails}
        />
        <Route path="/contacts/:slug/" component={ContactDetails} />
        <Route path="/contacts" component={Contacts} />
        <Route path="/integrations" component={Integrations} />
        <Route path={"/settings"} exact component={AccountInfo} />
        <Route path="*" exact={true} component={NotFound} />
      </Switch>
    </Suspense>
  );
};

const wrappedRoutes = () => (
  <Layout isShare={false}>
    <div className="app-content">
      <Route path="/" component={Pages} />
    </div>
  </Layout>
);

const Router = () => (
  <Switch>
    <Route exact path="/signup" component={Signup} />
    <Route exact path="/login" component={Login} />
    <Route exact path="/resetpassword" component={ResetPassword} />
    <Route exact path="/activateuser" component={Activateuser} />

    <Route path="/" component={wrappedRoutes} />
    <Route path="*" exact={true} component={NotFound} />
  </Switch>
);

export default Router;
