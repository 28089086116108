import { put, call, takeEvery, takeLatest } from "redux-saga/effects";
import { ContactAsset, Contact } from "../api/agent";
import handleFailedAuth from "../helpers/handleExpiredToken";

import {
  ADD_ASSET_CONTACT,
  ADD_ASSET_CONTACT_SUCCESS,
  ADD_ASSET_CONTACT_FAILURE,
  UPDATE_ASSET_CONTACT,
  UPDATE_ASSET_CONTACT_SUCCESS,
  UPDATE_ASSET_CONTACT_FAILURE,
  DELETE_ASSET_CONTACT,
  DELETE_ASSET_CONTACT_FAILURE,
  DELETE_ASSET_CONTACT_SUCCESS,
  GET_ASSET_CONTACTS,
  GET_ASSET_CONTACTS_SUCCESS,
  GET_ASSET_CONTACTS_FAILURE,
  GET_ALL_CONTACTS,
  GET_ALL_CONTACTS_SUCCESS,
  GET_ASSET_CHECKLIST,
  ADD_CONTACT,
  DELETE_CONTACT,
  EDIT_CONTACT,
  GET_CONTACT,
  GET_CONTACT_SUCCESS,
  GET_CLIENT_ASSETS_TRANSACTIONS,
  CLEAR_CONTACTS,
  PARSE_BULK_ADD_CONTACTS,
  PARSE_BULK_ADD_CONTACTS_SUCCESS,
  PARSE_BULK_ADD_CONTACTS_FAILURE,
  PARSE_BULK_ADD_CONTACTS_ASSETS,
  PARSE_BULK_ADD_CONTACTS_ASSETS_SUCCESS,
  PARSE_BULK_ADD_CONTACTS_ASSETS_FAILURE,
} from "../actions/actionTypes";
import showToast from "../helpers/toastNotification";
import { saveFile } from "../helpers";

// GET asset contact saga
function* getAssetContacts(action) {
  try {
    const payload = yield call(ContactAsset.getAssetContacts, action.payload);
    yield put({ type: GET_ASSET_CONTACTS_SUCCESS, payload });
  } catch (error) {
    const err = error.response.body;
    yield put({ type: GET_ASSET_CONTACTS_FAILURE, err });
  }
}
export function* watchGetAssetContacts() {
  yield takeLatest(GET_ASSET_CONTACTS, getAssetContacts);
}

//add asset contact saga
export function* addAssetContact(action) {
  try {
    const payload = yield call(ContactAsset.addAssetContact, action.payload);
    yield put({ type: ADD_ASSET_CONTACT_SUCCESS, payload });
    showToast("s", "Contact Added Successfully");
  } catch (error) {
    const err = error.response.body;
    showToast("e", err.message || "Unable To Add Contact");
    yield put({ type: ADD_ASSET_CONTACT_FAILURE, err });
  }
}
export function* watchAddAssetContact() {
  yield takeEvery(ADD_ASSET_CONTACT, addAssetContact);
}

//update asset contact saga
function* updateAssetContact(action) {
  try {
    const payload = yield call(ContactAsset.updateAssetContact, action.payload);
    yield put({ type: UPDATE_ASSET_CONTACT_SUCCESS, payload });
    showToast("s", "Contact Updated Successfully");
  } catch (error) {
    const err = error.response.body;
    showToast("e", err.message || "Unable To Update Contact");
    yield put({ type: UPDATE_ASSET_CONTACT_FAILURE, err });
  }
}
export function* watchUpdateAssetContact() {
  yield takeLatest(UPDATE_ASSET_CONTACT, updateAssetContact);
}

//delete asset contact saga
function* deleteAssetContact(action) {
  try {
    const slug = action.payload.slug;
    const payload = yield call(ContactAsset.deleteAssetContact, action.payload);
    showToast("s", "Contact Deleted Successfully");

    yield put({ type: GET_ASSET_CHECKLIST, payload: slug });
    yield put({ type: DELETE_ASSET_CONTACT_SUCCESS, payload });
  } catch (error) {
    const err = error.response.body;
    showToast("e", err.message || "Unable To Delete Contact");
    yield put({ type: DELETE_ASSET_CONTACT_FAILURE, err });
  }
}
export function* watchDeleteAssetContact() {
  yield takeLatest(DELETE_ASSET_CONTACT, deleteAssetContact);
}

//get all contacts
function* getAllContacts(action) {
  try {
    yield put({ type: CLEAR_CONTACTS });
    const payload = yield call(Contact.getAllContacts, action.payload);
    yield put({ type: GET_ALL_CONTACTS_SUCCESS, payload });
  } catch (error) {
    console.log(error);
    // const err = error.response.body;
    // yield put({ type: DELETE_ASSET_CONTACT_FAILURE, err });
  }
}
export function* watchGetAllContacts() {
  yield takeLatest(GET_ALL_CONTACTS, getAllContacts);
}

function* addContacts(action) {
  try {
    const { pagination, data } = action.payload;
    yield call(Contact.addContact, data);
    yield put({ type: GET_ALL_CONTACTS, payload: pagination });
    showToast("s", "Contact Added Successfully");
  } catch (e) {
    showToast("e", e.message || "Unable To Add Contact");
  }
}

export function* watchAddContacts() {
  yield takeLatest(ADD_CONTACT, addContacts);
}

function* deleteContacts(action) {
  try {
    const { slug, pagination } = action.payload;
    yield call(Contact.deleteContact, slug);
    yield put({ type: GET_ALL_CONTACTS, payload: pagination });
    showToast("s", "Contact Deleted Successfully");
  } catch (e) {
    console.log(e);
    showToast("e", e.message || "Unable To Delete Contact");
  }
}

export function* watchDeleteContacts() {
  yield takeLatest(DELETE_CONTACT, deleteContacts);
}

function* editContact(action) {
  try {
    const { data, slug, pagination } = action.payload;
    yield call(Contact.editContact, data, slug);
    yield put({ type: GET_ALL_CONTACTS, payload: pagination });
    showToast("s", "Contact Edited Successfully");
  } catch (e) {
    console.log(e);
    showToast("e", e.message || "Unable To Update Contact");
  }
}

export function* watchEditContacts() {
  yield takeLatest(EDIT_CONTACT, editContact);
}

function* getContact(action) {
  try {
    const payload = yield call(Contact.getContact, action.payload);
    yield put({ type: GET_CONTACT_SUCCESS, payload });
  } catch (e) {
    showToast("e", e.message || "Unable To Get Contact");
  }
}

export function* watchGetContact() {
  yield takeLatest(GET_CONTACT, getContact);
}

function* getClientAssetsTransactionExcel(action) {
  try {
    const { clientSlug, assetsSlug } = action.payload;
    const fileData = yield call(
      Contact.downloadClientAssetsTransactionExcel,
      clientSlug,
      assetsSlug
    );
    saveFile(fileData, "transaction_import.csv");
  } catch (e) {
    showToast("e", e.message || "Unable To Get Excel");
  }
}

export function* watchGetClientAssetsTransactionExcel() {
  yield takeLatest(
    GET_CLIENT_ASSETS_TRANSACTIONS,
    getClientAssetsTransactionExcel
  );
}

function* parseBulkAddContactsCsv(action) {
  try {
    const retsResp = yield call(
      Contact.parseBulkAddContactsCsv,
      action.payload
    );
    yield put({ type: PARSE_BULK_ADD_CONTACTS_SUCCESS, payload: retsResp });
  } catch (error) {
    if (error.response.status === 401 || error.response.statsCode === 401) {
      handleFailedAuth();
      return;
    }
    const err = error.response.body;
    showToast("e", "Unable to parse contacts");
    yield put({ type: PARSE_BULK_ADD_CONTACTS_FAILURE, err });
  }
}

export function* watchParseBulkAddContactsCsv() {
  yield takeLatest(PARSE_BULK_ADD_CONTACTS, parseBulkAddContactsCsv);
}

function* parseBulkAddContactsAssetsCsv(action) {
  try {
    const retsResp = yield call(
      Contact.parseBulkAddContactsAssetsCsv,
      action.payload
    );
    yield put({
      type: PARSE_BULK_ADD_CONTACTS_ASSETS_SUCCESS,
      payload: retsResp,
    });
  } catch (error) {
    if (error.response.status === 401 || error.response.statsCode === 401) {
      handleFailedAuth();
      return;
    }
    const err = error.response.body;
    showToast("e", "Unable to parse contacts");
    yield put({ type: PARSE_BULK_ADD_CONTACTS_ASSETS_FAILURE, err });
  }
}

export function* watchParseBulkAddContactsAssetsCsv() {
  yield takeLatest(
    PARSE_BULK_ADD_CONTACTS_ASSETS,
    parseBulkAddContactsAssetsCsv
  );
}
