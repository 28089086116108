import React, { Component } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import FormField from "../../shared/components/form/FormField";
import ImageField from "../../shared/components/form/ImageField";
import { connect } from "react-redux";

import { Row, Col, Card, CardBody } from "reactstrap";
import {
  getUserDetail,
  updateInvestorProfile,
  updatePassword,
  updateUser,
} from "../../actions/auth/authAction";
import { uploadFile } from "../../actions/resource";

const AccountInfoForm = ({
  handleSubmit,
  handleUploadFile,
  handleReset,
  loading,
  error,
  success,
}) => (
  <div>
    <div className="pb-3">
      <h4>{"Account Settings"}</h4>
    </div>
    <form onSubmit={handleSubmit}>
      <Row>
        <Col md={6} lg={6} xl={6}>
          <div className="inputblock m-3">
            <Field
              name="picture"
              component={ImageField}
              handleUploadFile={handleUploadFile}
              width="150px"
              height="150px"
            />
          </div>
          <div className="form-group inputblock">
            <label htmlFor="exampleInputEmail1">Email:</label>
            <Field
              name="email"
              component={FormField}
              type="email"
              placeholder="Email"
              disabled
            />
          </div>
        </Col>
        <Col md={6} lg={6} xl={6}>
          <div className="form-group inputblock">
            <label htmlFor="exampleInputEmail1">Full Name:</label>
            <Field
              name="fullName"
              component={FormField}
              placeholder="Full Name"
            />
          </div>
          <div className="form-group inputblock">
            <label htmlFor="exampleInputEmail1">Current Password:</label>
            <Field
              name="currentPassword"
              component={FormField}
              type="password"
              placeholder="Current password"
            />
          </div>

          <div className="form-group inputblock">
            <label htmlFor="exampleInputEmail1">New Password:</label>
            <Field
              name="password"
              component={FormField}
              type="password"
              placeholder="New password"
            />
          </div>
        </Col>
      </Row>
      <div className="py-5 text-center">
        <button
          type="submit"
          className="btn btn-primary text-uppercase px-5"
          disabled={loading}
        >
          Update
        </button>
      </div>
    </form>
  </div>
);

const validationSchema = Yup.object({
  fullName: Yup.string("")
    .required("Name is required")
    .max(100, "Name too long"),
  email: Yup.string("")
    .email("Enter valid email")
    .required("Email is required")
    .max(100, "Email too long"),
  currentPassword: Yup.string("").max(20, "Password too long"),
  password: Yup.string("")
    .min(8, "Password must contain at least 8 characters")
    // .notOneOf([Yup.ref('password')], 'Current password and new password should be different')
    .max(20, "Password too long"),
});

class AccountInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      success: null,
      uploadingFile: false,
      uploadProp: {},
    };
  }

  resetForm = null;

  handleUploadFile = (files) => {
    this.setState({ uploadingFile: true });
    this.props.uploadFile({
      tags: "user, profile",
      folder: "users",
      files: files,
    });
  };

  handleSubmit = (newData) => {
    const { updateUser } = this.props;
    updateUser(newData);
  };

  updateInfo = (values) => {
    this.setState({ loading: true, error: null, success: null }, () => {
      this.handleSubmit({
        name: values.fullName,
        password: values.password,
        currentpassword: values.currentPassword,
        pictureId: values.pictureId,
      });
    });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps && this.state.loading) {
      if (nextProps.userDetail.updateUserDetailSuccess === true) {
        this.setState({
          loading: false,
          success: "Updated Successfully",
          error: null,
        });
        this.resetForm({
          email: nextProps.userDetail.userInfo.email,
          fullName: nextProps.userDetail.userInfo.fullName,
          currentPassword: "",
          password: "",
        });
        // this.handleReset({email:nextProps.userDetail.userInfo.email, fullName:nextProps.userDetail.userInfo.fullName})
      } else if (nextProps.userDetail.updateUserDetailSuccess === false) {
        this.resetForm({ currentPassword: "", password: "" });
        this.setState({
          loading: false,
          error: nextProps.userDetail.error,
          success: null,
        });
      }
    } else if (this.state.uploadingFile) {
      if (nextProps.uploadProp) {
        this.setState({
          uploadingFile: false,
          uploadProp: nextProps.uploadProp,
        });
      }
    }
  }

  render() {
    const {
      userDetail: { userInfo },
    } = this.props;

    const {
      uploadProp: { displayUrl, fileId },
    } = this.state;

    let initialValues = {
      fullName: userInfo.fullName || "",
      pictureId: fileId || userInfo.pictureId || "",
      email: userInfo.email || "",
      picture:
        displayUrl || userInfo.picture || "/assets/images/default_user.png",
      password: "",
      currentPassword: "",
    };

    return (
      <Card className={"m-5"}>
        <CardBody>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={this.updateInfo}
            // onReset={this.closeModal}
            render={(props) => {
              this.resetForm = props.resetForm;
              return (
                <AccountInfoForm
                  handleSubmit={props.handleSubmit}
                  {...this.props}
                  loading={this.state.loading}
                  error={this.state.error}
                  success={this.state.success}
                  handleUploadFile={this.handleUploadFile}
                />
              );
            }}
          />
        </CardBody>
      </Card>
    );
  }
}

const mapStatToProps = (state) => {
  return {
    userDetail: state.userDetail,
    uploadProp: state.upload,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (userData) => {
      updateUser(userData, dispatch);
    },
    uploadFile: (data) => uploadFile(data, dispatch),
    updatePassword: (userData) => {
      updatePassword(userData, dispatch);
    },
    updateInvestorProfile: (userData) => {
      updateInvestorProfile(userData, dispatch);
    },
    getUserDetail: () => {
      getUserDetail(dispatch);
    },
  };
};

export default connect(mapStatToProps, mapDispatchToProps)(AccountInfo);
