import React, { Component } from "react";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import RouterLink from "../ReactMetismenuRouterLink";

import MetisMenu from "react-metismenu";

const sidebarMenuContent = [
  {
    label: "Dashboard",
    icon: "tachometer-alt",
    description: "Dashboard page.",
    to: "/dashboard",
  },
  {
    label: "Chats",
    icon: "comment",
    description: "Chats page.",
    to: "/chats",
  },
  {
    label: "Reminders",
    icon: "calendar-alt",
    description: "Reminder page.",
    to: "/reminders",
  },
];
class SidebarContent extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    this.props.onClick();
  };

  render() {
    return (
      <PerfectScrollbar>
        <div className="sidebar-navigation">
          <div className="sidebar-header">
            <span>Navigation menu</span>
          </div>
          <MetisMenu
            content={sidebarMenuContent}
            LinkComponent={RouterLink}
            activeLinkFromLocation
            iconNamePrefix="fa fa-"
            noBuiltInClassNames={true}
            classNameItemActive="active"
            classNameIcon="sidebar-icon"
            iconNameStateVisible="sidebar-icon-indicator"
            iconNameStateHidden="sidebar-icon-indicator"
            classNameItemHasVisibleChild="submenu-open"
            classNameItemHasActiveChild="active"
            classNameStateIcon="pe-7s-angle-down"
          />
        </div>
      </PerfectScrollbar>
    );
  }
}

export default SidebarContent;
