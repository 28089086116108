import React, { Fragment, Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import SidebarContent from "./SidebarContent";
import { SidebarProps } from "../../../../shared/prop-types/ReducerProps";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../../../../img/logo.png";
import { faDotCircle } from "@fortawesome/free-solid-svg-icons";
import { faArrowsAltH } from "@fortawesome/free-solid-svg-icons";

import { UncontrolledTooltip } from "reactstrap";

class Sidebar extends Component {
  render() {
    const sidebarClass = classNames({
      "app-sidebar bg-light app-sidebar--light app-sidebar--shadow": true,
    });
    return (
      <div>
        <div className={sidebarClass}>
          <Fragment>
            <div className="app-sidebar--header">
              <div className="header-nav-logo">
                <Link
                  to="/dashboard"
                  className="nav-logo"
                  title="BetterCapital"
                >
                  <img alt="BetterCapital.US" src={Logo} width={180} />
                </Link>
              </div>
              <button
                onClick={this.props.changeMobileSidebarVisibility}
                className="btn btn-sm collapse-sidebar-btn"
                id="CollapseSidebarTooltip"
              >
                <FontAwesomeIcon icon={faDotCircle} size="lg" />
              </button>
              <UncontrolledTooltip
                target="CollapseSidebarTooltip"
                container=".app-sidebar"
                placement="right"
              >
                Collapse sidebar
              </UncontrolledTooltip>
              <button
                className={clsx(
                  "navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn",
                  { "is-active": this.props.sidebar.show }
                )}
                onClick={this.props.changeMobileSidebarVisibility}
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </button>
              <button
                onClick={this.props.changeMobileSidebarVisibility}
                className="expand-sidebar-btn btn btn-sm"
                id="ExpandSidebarTooltip"
              >
                <FontAwesomeIcon icon={faArrowsAltH} />
              </button>
              <UncontrolledTooltip
                target="ExpandSidebarTooltip"
                container=".app-sidebar"
                placement="right"
              >
                Expand sidebar
              </UncontrolledTooltip>
            </div>
          </Fragment>
          <div className="app-sidebar--content">
            <SidebarContent
              onClick={this.props.changeMobileSidebarVisibility}
            />
          </div>
        </div>

        <div
          onClick={this.props.changeMobileSidebarVisibility}
          className={clsx("app-sidebar-overlay", {
            "is-active": this.props.sidebar.show,
          })}
        />
      </div>
    );
  }
}

Sidebar.propTypes = {
  sidebar: SidebarProps.isRequired,
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
};

export default Sidebar;
