import React, { PureComponent } from "react";
import { Alert } from "reactstrap";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";

export default class AlertComponent extends PureComponent {
  static propTypes = {
    color: PropTypes.string,
    children: PropTypes.element.isRequired,
  };

  static defaultProps = {
    color: "",
  };

  render() {
    const { color, children } = this.props;
    let icon;
    switch (color) {
      case "info":
        icon = faInfoCircle;
        break;
      case "success":
        icon = faThumbsUp;
        break;
      case "warning":
        icon = faExclamation;
        break;
      case "danger":
        icon = faTimesCircle;
        break;
      default:
        break;
    }

    return (
      <Alert color={color}>
        <div className="d-flex align-items-center align-content-center">
          <span className="font-size-lg d-block d-40 mr-3 text-center bg-white-50 rounded-sm">
            <FontAwesomeIcon icon={icon} />
          </span>
          <span>{children}</span>
        </div>
      </Alert>
    );
  }
}
