import { call, takeLatest } from "redux-saga/effects";
import { Testimonial } from "../api/agent";
import { REQUEST_TESTIMONIAL } from "../actions/actionTypes";
import showToast from "../helpers/toastNotification";

function* requestTestimonial(action) {
  try {
    const { slug, personalNote } = action.payload;
    yield call(Testimonial.request, slug, personalNote);
    showToast("s", "Request Testimonial successfully send");
  } catch (e) {
    showToast("e", "Unable To Request Testimonial");
  }
}

export function* watchRequestTestimonial() {
  yield takeLatest(REQUEST_TESTIMONIAL, requestTestimonial);
}
