import React from "react";
import { withFormik, Field, FieldArray } from "formik";
import { Button } from "reactstrap";
import TextareaField from "../../../../shared/components/form/Textarea";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import showToast from "../../../../helpers/toastNotification";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const FAQsForm = (props) => {
  const {
    // Formik HOC props
    values,
    errors,
    isSubmitting,
    handleSubmit,

    // Loki props
    backHandler,
    cantBack,
  } = props;

  if (
    props.isSubmitting &&
    props.isValidating &&
    errors.questions &&
    errors.questions.filter(Boolean).length > 0
  ) {
    showToast("w", "Remove any un-filled FAQ's");
  }

  return (
    <div className="wizard-steps horizontal">
      <div className="card-box p-4">
        <form onSubmit={handleSubmit} className="px-2">
          <FieldArray
            name="questions"
            render={(arrayHelpers) => (
              <div>
                <div className="card-header pb-2">
                  <div className="card-header--title">
                    <h3 className="mb-0 py-2 font-weight-bold">
                      Common questions you get
                    </h3>
                  </div>
                  <div className="card-header--actions">
                    <Button
                      size="lg"
                      color="first"
                      onClick={() => {
                        if (values.questions.length < 4) {
                          return arrayHelpers.push({
                            question: "",
                            answer: "",
                          });
                        }
                      }}
                      className="py-1 px-3 text-uppercase"
                    >
                      <span className="btn-wrapper--icon">
                        <FontAwesomeIcon icon={faPlus} />
                      </span>
                      <span className="btn-wrapper--label">Add Question</span>
                    </Button>
                  </div>
                </div>
                {values.questions.length === 0 && (
                  <p>You don't have any questions.</p>
                )}
                {values.questions.map((q, i) => (
                  <div key={i}>
                    <Button
                      color="second"
                      className="float-right"
                      onClick={(e) => {
                        e.preventDefault();
                        arrayHelpers.pop(i);
                      }}
                    >
                      Remove
                    </Button>
                    <div className="clearfix" />
                    <div className="row">
                      <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6 inputblock">
                        <label htmlFor="exampleInputEmail1">
                          Question #{`${i + 1}`}:
                        </label>
                        <Field
                          name={`questions.${i}.question`}
                          component={TextareaField}
                          placeholder="Input Question"
                          rows="4"
                        />
                      </div>
                      <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6  inputblock">
                        <label htmlFor="exampleInputEmail1">Answer:</label>
                        <Field
                          name={`questions.${i}.answer`}
                          component={TextareaField}
                          placeholder="Input Answer"
                          rows="4"
                          maxlength="500"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          />

          <div className="actions p-4">
            <Button
              outline
              color="primary"
              onClick={backHandler}
              disabled={cantBack || isSubmitting}
            >
              Back
            </Button>

            <Button color="primary" disabled={isSubmitting} type="submit">
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default withFormik({
  mapPropsToValues: (props) => ({
    slug: props.partner.slug,
    name: props.partner.name,
    bio: props.partner.bio,
    imageUrl: props.partner.imageUrl,
    imageId: props.partner.imageId,
    videoUrl: props.partner.videoUrl,
    videoId: props.partner.videoId,
    tags: props.partner.tags,
    category: props.partner.category,
    questions: props.partner.questions,
    highlights: props.partner.highlights,
    plans: props.partner.plans,
  }),

  validationSchema: Yup.object().shape({
    questions: Yup.array().of(
      Yup.object({
        question: Yup.string()
          .required("Question is required")
          .max(100, "Question too long. Max characters 100"),
        answer: Yup.string()
          .required("Answer is required")
          .max(500, "Answer too long. Max characters 500"),
      })
    ),
  }),
  handleSubmit: (values, { props }) => {
    props.nextHandler(values);
  },
})(FAQsForm);
