import React, { PureComponent } from "react";
import Select from "react-select";
import { getIn } from "formik";
import classNames from "classnames";

class SelectField extends PureComponent {
  render() {
    const {
      options,
      field,
      form: { touched, errors, setFieldValue },
      placeholder,
      searchable,
    } = this.props;
    const touch = getIn(touched, field.name);
    const errorMessage = getIn(errors, field.name);

    return (
      <div>
        <Select
          options={options}
          name={field.name}
          placeholder={placeholder}
          isSearchable={searchable}
          value={
            options
              ? options.find((option) => option.value === field.value)
              : ""
          }
          onChange={(option) => {
            setFieldValue(field.name, option ? option.value : "");
            if (this.props.onChange) this.props.onChange(option.value);
          }}
          defaultValue={options[0] && options[0].value}
          isClearable={false}
          className={classNames({
            "is-invalid": touch && errorMessage,
          })}
        />
        <div className="invalid-feedback">{errorMessage}</div>
      </div>
    );
  }
}

export default SelectField;
