import {
  UPLOAD_FILES,
  UPLOAD_FILE_FAILURE,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILES_SUCCESS,
  UPLOAD_FILES_FAILURE,
} from "../actions/actionTypes";

import {
  setLoadingState,
  setSuccessResponse,
  setErrorResponse,
} from "./reducerUtils";

const initialState = {
  upload: {
    data: [],
    state: "INIT",
    error: null,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPLOAD_FILE_SUCCESS:
      return {
        displayUrl: action.payload.displayUrl,
        fileName: action.payload.fileName,
        fileId: action.payload.fileId,
        uploadFileSuccess: true,
      };
    case UPLOAD_FILES:
      return setLoadingState(state, "upload");

    case UPLOAD_FILES_SUCCESS:
      return setSuccessResponse(state, action, "upload");
    case UPLOAD_FILES_FAILURE:
      return setErrorResponse(state, action.err, "upload");
    case UPLOAD_FILE_FAILURE:
      return {
        uploadFileSuccess: false,
        error: "Unable to upload Image",
      };
    default:
      return state;
  }
}
