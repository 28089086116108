// import isEmpty from '../validation/is-empty';

import {
  GET_RECOMMENDATIONS_SUCCESS,
  GET_RECOMMENDATIONS_FAILURE,
} from "../actions/actionTypes";
const initialState = {
  resources: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_RECOMMENDATIONS_SUCCESS:
      return {
        getRecommendationsSuccess: true,
        recommendations: action.payload.resources,
      };
    case GET_RECOMMENDATIONS_FAILURE:
      return {
        getRecommendationsSuccess: false,
        recommendations: state.recommendations,
      };

    default:
      return state;
  }
}
