/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getNotificationList } from "../../../actions/notification";
import Icon from "@mdi/react";
import { mdiAlphaEBox } from "@mdi/js";
import { mdiAlphaIBox } from "@mdi/js";
import { mdiAlphaRBox } from "@mdi/js";
import moment from "moment";
import _ from "lodash";
import parse from "html-react-parser";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PerfectScrollbar from "react-perfect-scrollbar";
import { UncontrolledPopover, Badge, Button } from "reactstrap";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faBell } from "@fortawesome/free-solid-svg-icons";

class TopbarNotification extends PureComponent {
  state = {
    isOpen: false,
  };

  componentDidMount() {
    this.props.getNotificationList();
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  notificationIcon = (category) => {
    switch (category) {
      case "income":
        return (
          <div className="topbar__collapse-icon-wrap">
            <Icon path={mdiAlphaIBox} size={2} color="#61697a" />
          </div>
        );
      case "reminder":
        return (
          <div className="topbar__collapse-icon-wrap">
            <Icon path={mdiAlphaRBox} size={2} color="#61697a" />
          </div>
        );
      default:
        return (
          <div className="topbar__collapse-icon-wrap">
            <Icon path={mdiAlphaEBox} size={2} color="#61697a" />
          </div>
        );
    }
  };
  render() {
    const { notifications } = this.props.notifications;
    notifications.sort((n1, n2) => n1.createdAt < n2.createdAt);
    let groups = _.groupBy(notifications, function (notification) {
      return moment(notification.createdAt).startOf("day").format("Do MMM");
    });

    return (
      <div className="d-flex align-items-center popover-header-wrapper">
        <span className="d-inline-block pr-2">
          <Button
            id="alertsPopover"
            color="neutral-success"
            className="bg-neutral-success text-success font-size-lg p-0 mx-2 d-inline-block shadow-none border-0 text-center d-44 rounded position-relative btn-transition-none"
          >
            <span>
              <Badge color="success" className="badge-circle">
                New notifications
              </Badge>
              <FontAwesomeIcon icon={faBell} size="lg" />
            </span>
          </Button>
          <UncontrolledPopover
            target="alertsPopover"
            trigger="legacy"
            container="body"
            className="popover-custom-wrapper"
            placement="auto"
            toggle={this.toggle}
            isOpen={this.state.isOpen}
          >
            <div className="bg-composed-wrapper bg-midnight-bloom mx-3 mt-3 border-0 text-center rounded-sm">
              <div className="bg-composed-img-3 bg-composed-wrapper--image" />
              <div className="bg-composed-wrapper--content text-light px-2 py-4">
                <h4 className="font-size-xl font-weight-bold mb-2">
                  Notifications
                </h4>
                <p className="opacity-8 mb-0">
                  You have{" "}
                  <b className="text-success">{notifications.length}</b> new
                  messages
                </p>
              </div>
            </div>
            <div className="scroll-area scroll-area-sm shadow-overflow p-2">
              <PerfectScrollbar>
                <div className="timeline-list timeline-list-offset timeline-list-offset-dot">
                  {Object.keys(groups).map((date, idx) => {
                    return (
                      <div key={`notification-${idx}`}>
                        <b>{parse(date)}</b>
                        {groups[date].map((notification, index) => (
                          <div
                            className="timeline-item"
                            key={`timeline-item-${index}`}
                          >
                            <div className="timeline-item-offset">
                              {moment(notification.createdAt).format("hh mm")}
                            </div>
                            <div className="timeline-item--content">
                              <div className="timeline-item--icon" />
                              <h4 className="timeline-item--label mb-2 font-weight-bold">
                                {notification.category}
                              </h4>
                              {notification.type === "asset" &&
                              notification.transaction ? (
                                <Link
                                  className="topbar__collapse-message"
                                  to={`/dashboard/assets/${
                                    notification.asset
                                      ? notification.asset.slug
                                      : null
                                  }/transactions?unpaidId=${
                                    notification.transaction
                                  }`}
                                >
                                  {notification.description}
                                </Link>
                              ) : (
                                <Link
                                  className="topbar__collapse-message"
                                  to="/reminders"
                                >
                                  {notification.description}
                                </Link>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    );
                  })}
                </div>
              </PerfectScrollbar>
            </div>
            <div className="text-center py-3">
              <Link to="/reminders" onClick={this.toggle}>
                <Button
                  color="primary"
                  className="px-4 btn-gradient badge-wrapper bg-midnight-bloom"
                >
                  <Badge
                    color="warning"
                    className="badge-position badge-position--top-right badge-circle-inner"
                  >
                    New notifications
                  </Badge>
                  <span className="btn-wrapper--label">View all</span>
                  <span className="btn-wrapper--icon">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </span>
                </Button>
              </Link>
            </div>
          </UncontrolledPopover>
        </span>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  notifications: state.notifications,
});
const mapDispatchToProps = (dispatch) => ({
  getNotificationList: () => getNotificationList(dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(TopbarNotification);
