// import isEmpty from '../validation/is-empty';
import {} from "../actions/auth/authActionTypes";
import {
  GET_RESOURCES_SUCCESS,
  GET_RESOURCES_FAILURE,
  RESERVE_RESOURCE_SUCCESS,
  RESERVE_RESOURCE_FAILURE,
  POST_RESOURCE_SUCCESS,
  POST_RESOURCE_FAILURE,
  GET_MY_RESOURCES_SUCCESS,
  GET_MY_RESOURCES_FAILURE,
  GET_VIEW_RESOURCE_SUCCESS,
  GET_VIEW_RESOURCE_FAILURE,
} from "../actions/actionTypes";
const initialState = {
  resources: [],
  meta: { totalCount: 0 },
  resourceStats: {
    webinar: 0,
    video: 0,
    blog: 0,
    live: 0,
  },
  myResourceStats: {
    webinar: 0,
    video: 0,
    blog: 0,
    live: 0,
  },
  myResources: [],
  viewResource: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_RESOURCES_SUCCESS:
      return {
        ...state,
        getResourcesSuccess: true,
        resources: action.payload.resources,
        meta: action.payload.meta,
      };
    case GET_RESOURCES_FAILURE:
      return {
        ...state,
        getResourcesSuccess: false,
        resources: state.resources,
        meta: state.meta,
      };

    case RESERVE_RESOURCE_SUCCESS:
      const index = state.resources.findIndex(
        (d) => d._id.toString() === action.payload.resource._id.toString()
      );
      if (index > -1) state.resources[index] = action.payload.resource;
      return {
        ...state,
        resources: state.resources,
        reserverResourceSuccess: true,
        meta: state.meta,
      };
    case RESERVE_RESOURCE_FAILURE:
      return {
        ...state,
        resources: state.resources,
        reserverResourceSuccess: false,
        error: action.err.message,
        meta: state.meta,
      };

    case "GET_RESOURCE_STATS_SUCCESS":
      return {
        ...state,
        resourceStats: action.payload.resourceStats,
      };
    case "GET_MY_RESOURCE_STATS_SUCCESS":
      return {
        ...state,
        myResourceStats: action.payload.myResourceStats,
      };

    case POST_RESOURCE_SUCCESS:
      return {
        ...state,
        postResourceSuccess: true,
      };

    case POST_RESOURCE_FAILURE:
      return {
        ...state,
        postResourceSuccess: false,
      };

    case GET_MY_RESOURCES_SUCCESS:
      return {
        ...state,
        getMyResourcesSuccess: true,
        myResources: action.payload.myResources,
      };

    case GET_MY_RESOURCES_FAILURE:
      return {
        ...state,
        getMyResourcesSuccess: false,
        myResources: [],
      };

    //view resource
    case GET_VIEW_RESOURCE_SUCCESS:
      return {
        ...state,
        getViewResourceSuccess: true,
        viewResource: action.payload.resource,
      };

    case GET_VIEW_RESOURCE_FAILURE:
      return {
        ...state,
        getViewResourceSuccess: false,
        viewResource: state.viewResource,
      };

    default:
      return state;
  }
}
