import React, { Component } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";

import FormField from "../../shared/components/form/FormField";
import TextAreaField from "../../shared/components/form/Textarea";

import DatePickerField from "../../shared/components/form/DatePicker";
import SelectField from "../../shared/components/form/SelectField";

import { addReminder } from "../../actions/reminder";

const AddReminderModal = ({
  modalOpen,
  handleSubmit,
  handleReset,
  loading,
  values,
  error,
}) => (
  <Modal
    isOpen={modalOpen}
    className={"modal-dialog modal-dialog-centered"}
    contentClassName={"newexpensemodal"}
  >
    <ModalHeader toggle={handleReset}>Add Reminder</ModalHeader>
    <form onSubmit={handleSubmit}>
      <ModalBody>
        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-2 col-form-label">
            Title:
          </label>
          <div className="col-sm-10 inputblock">
            <Field
              name="title"
              component={FormField}
              type="text"
              placeholder="Enter Reminder Title"
            />
          </div>
        </div>
        {values.showDateField && (
          <div className="form-group row">
            <label htmlFor="staticEmail" className="col-sm-2 col-form-label">
              Date:
            </label>
            <div className="col-sm-10 inputblock">
              <Field
                name="date"
                component={DatePickerField}
                minDate={new Date()}
                placeholder="Pick Date"
              />
            </div>
          </div>
        )}
        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-2 col-form-label">
            Note:
          </label>
          <div className="col-sm-10 inputblock">
            <Field
              name="note"
              component={TextAreaField}
              placeholder="Jot down important notes..."
              rows={3}
            />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-2 col-form-label">
            Frequency:
          </label>
          <div className="col-sm-10 inputblock">
            <Field
              name="frequency"
              component={SelectField}
              options={[
                { value: "One-Time", label: "One-Time" },
                { value: "Monthly", label: "Monthly" },
                { value: "Yearly", label: "Yearly" },
                { value: "Daily", label: "Daily" },
              ]}
              placeholder="Select Frequency"
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          type="reset"
          className="btn btn-secondary px-5 d-inline-block mx-2"
          onClick={handleReset}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          className="btn savebtns btn-primary px-5  d-inline-block mx-2"
          type="submit"
          color="primary"
          disabled={loading}
        >
          {loading ? "Saving" : "Save"}
        </Button>
      </ModalFooter>
    </form>
  </Modal>
);

const validationSchema = Yup.object({
  title: Yup.string("")
    .required("Title is required")
    .max(100, "Title too long"),
  date: Yup.date().required("Date is required"),
  note: Yup.string(""),
  frequency: Yup.string(""),
});

class AddReminder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
    };
  }

  fomrikResetForm;

  addReminder = (values) => {
    const { addReminder } = this.props;
    // return
    this.setState({ loading: true, error: null }, () => {
      addReminder({
        title: values.title,
        note: values.note,
        priority: values.priority,
        date: values.date,
        frequency: values.frequency,
      });
    });
  };

  closeModal = () => {
    const { toggleModal } = this.props;
    this.setState({ loading: false, error: null }, () => {
      this.fomrikResetForm({ title: "", priority: "high" });
      toggleModal(null);
    });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps && this.state.loading) {
      const { reminders } = nextProps;
      if (reminders.addReminderSuccess === true) {
        this.closeModal();
      } else if (reminders.addReminderSuccess === false)
        this.setState({ loading: false, error: reminders.errors });
    }
  }

  render() {
    const initialValues = {
      title: "",
      priority: "high",
      note: "",
      date: this.props.selectedData
        ? new Date(this.props.selectedData.start)
        : null,
      showDateField: this.props.selectedData ? false : true,
      frequency: "One-Time",
    };

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={this.addReminder}
        render={(props) => {
          //to reset values later
          this.fomrikResetForm = props.resetForm;
          return (
            <AddReminderModal
              values={props.values}
              handleSubmit={props.handleSubmit}
              handleReset={this.closeModal}
              {...this.props}
              loading={this.state.loading}
              error={this.state.error}
            />
          );
        }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  reminders: state.reminders,
});

const mapDispatchToProps = (dispatch) => ({
  addReminder: (data) => addReminder(data, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddReminder);
