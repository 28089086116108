import React, { PureComponent } from "react";
import { Link, withRouter } from "react-router-dom";
import { Nav, NavItem, NavLink } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTachometerAlt,
  faComment,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";

class TopbarNav extends PureComponent {
  state = {
    isOpen: false,
  };
  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  render() {
    const { location } = this.props;
    const locationName = location.pathname;
    const routes = locationName.split("/");
    let tabname;
    if (routes[1] === "reminders") tabname = "REMINDERS";
    else if (routes[1] === "chats") tabname = "CHATS";
    else if (routes[1] === "settings") tabname = "SETTINGS";
    else if (routes[1] === "assets") tabname = "ASSETS";
    else tabname = "DASHBOARD";
    return (
      <Nav className="justify-content-center">
        <NavItem>
          <NavLink tag={Link} to="/dashboard" active={tabname === "DASHBOARD"}>
            <div className="nav-link-icon">
              <FontAwesomeIcon icon={faTachometerAlt} />
            </div>
            <span>Dashboard</span>
          </NavLink>
        </NavItem>

        {/*<NavItem>*/}
        {/*  <NavLink tag={Link} to="/assets" active={tabname === "ASSETS"}>*/}
        {/*    <div className="nav-link-icon">*/}
        {/*      <FontAwesomeIcon icon={faUsers} />*/}
        {/*    </div>*/}
        {/*    <span>Assets</span>*/}
        {/*  </NavLink>*/}
        {/*</NavItem>*/}

        <NavItem>
          <NavLink tag={Link} to="/chats" active={tabname === "CHATS"}>
            <div className="nav-link-icon">
              <FontAwesomeIcon icon={faComment} />
            </div>
            <span>Chats</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to="/reminders" active={tabname === "REMINDERS"}>
            <div className="nav-link-icon">
              <FontAwesomeIcon icon={faCalendarAlt} />
            </div>
            <span>Reminders</span>
          </NavLink>
        </NavItem>
      </Nav>
    );
  }
}

export default withRouter(TopbarNav);
