import {
  GET_DASHBOARD_OVERVIEW,
  GET_DASHBOARD_OVERVIEW_SUCCESS,
  GET_PARTNER_DASHBOARD_STATS,
  GET_PARTNER_DASHBOARD_STATS_SUCCESS,
} from "../actions/actionTypes";

import { User } from "../api/agent";

import { takeLatest, call, put } from "redux-saga/effects";
function* getDashboardOverview() {
  try {
    const payload = yield call(User.getOverview);
    yield put({ type: GET_DASHBOARD_OVERVIEW_SUCCESS, payload });
  } catch (error) {}
}

export function* watchGetDashboardOverview() {
  yield takeLatest(GET_DASHBOARD_OVERVIEW, getDashboardOverview);
}

function* getDashboardStats() {
  try {
    const payload = yield call(User.getStats);
    yield put({ type: GET_PARTNER_DASHBOARD_STATS_SUCCESS, payload });
  } catch (e) {
    console.log(e);
  }
}

export function* watchGetPartnerDashboardStats() {
  yield takeLatest(GET_PARTNER_DASHBOARD_STATS, getDashboardStats);
}
