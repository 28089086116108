import React, { Component } from "react";
import { Formik /* Field */ } from "formik";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { updateReminder } from "../../actions/reminder";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faArrowsAlt } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const ViewReminderModal = ({
  modalOpen,
  /* handleSubmit, */ handleReset,
  loading,
  error,
  selectedData,
  performUpdateAction,
  handleDateChange,
}) => (
  <Modal
    isOpen={modalOpen}
    className={"modal-dialog modal-dialog-centered"}
    contentClassName={"newexpensemodal"}
  >
    <ModalHeader toggle={handleReset}>
      {selectedData && selectedData.title}
    </ModalHeader>

    <form /* onSubmit={handleSubmit} */>
      <ModalBody>
        <div className="form-group row">
          <div className="col-sm-8">{selectedData && selectedData.desc}</div>
        </div>
        <div className="form-group row ml-1">
          <Button
            color="primary"
            onClick={() => performUpdateAction("markCompleted")}
            disabled={
              selectedData && selectedData.status === "pending" ? false : true
            }
          >
            <span className="btn-wrapper--icon">
              <FontAwesomeIcon icon={faCheck} />
            </span>
            <span className="btn-wrapper--label">
              {selectedData && selectedData.status === "pending"
                ? "Mark Completed"
                : "Completed!"}
            </span>
          </Button>
          <DatePicker
            className="btn btn-secondary view-reminder-btn"
            dateFormat="MM/dd/yyyy"
            selected={selectedData ? selectedData.start : null}
            onChange={handleDateChange}
            // minDate={new Date()}
            showMonthDropdown
            showYearDropdown
            customInput={
              <Button>
                <span className="btn-wrapper--icon">
                  <FontAwesomeIcon icon={faArrowsAlt} />
                </span>
                <span className="btn-wrapper--label">Move Reminder</span>
              </Button>
            }
          />
          <Button color="danger" onClick={() => performUpdateAction("remove")}>
            <span className="btn-wrapper--icon">
              <FontAwesomeIcon icon={faTrashAlt} />
            </span>
            <span className="btn-wrapper--label">Remove</span>
          </Button>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          type="reset"
          color="primary"
          className="btn btn-secondary px-5 d-inline-block mx-2"
          onClick={handleReset}
          disabled={loading}
        >
          Cancel
        </Button>
        {/* <Button
            className="btn savebtns btn-primary px-5  d-inline-block mx-2"
            type="submit"
            disabled={loading}
          >
            {loading ? 'Removing' : 'Remove'}
          </Button> */}
      </ModalFooter>
    </form>
  </Modal>
);

class ViewReminder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
    };
  }

  handleDateChange = (date) => {
    this.performUpdateAction("moveReminder", { date });
  };

  performUpdateAction = (type, data = {}) => {
    const { selectedData, updateReminder } = this.props;
    if (selectedData) {
      this.setState({ loading: true, error: null }, () => {
        updateReminder({
          remId: selectedData._id,
          updateType: type,
          ...data,
        });
      });
    }
  };

  closeModal = () => {
    const { toggleModal } = this.props;
    this.setState({ loading: false, error: null }, () => {
      // this.fomrikResetForm({ title: '' });
      toggleModal(null);
    });
  };

  componentWillReceiveProps(nextProps) {
    if (this.state.loading) {
      const { reminders } = nextProps;
      if (reminders.updateReminderSuccess === true) {
        this.closeModal();
      } else if (reminders.updateReminderSuccess === false)
        this.setState({ loading: false, error: reminders.errors });
    }
  }

  render() {
    const initialValues = {};
    return (
      <Formik
        initialValues={initialValues}
        onReset={this.closeModal}
        render={(props) => {
          return (
            <ViewReminderModal
              handleSubmit={props.handleSubmit}
              handleReset={props.handleReset}
              {...this.props}
              loading={this.state.loading}
              error={this.state.error}
              performUpdateAction={this.performUpdateAction}
              handleDateChange={this.handleDateChange}
            />
          );
        }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  reminders: state.reminders,
});

const mapDispatchToProps = (dispatch) => ({
  updateReminder: (data) => updateReminder(data, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(ViewReminder);
