import React, { PureComponent } from "react";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { getIn } from "formik";
import clsx from "clsx";

class TagsInputField extends PureComponent {
  render() {
    const {
      field,
      form: { setFieldValue, touched, errors },
    } = this.props;
    const touch = getIn(touched, field.name);
    const errorMessage = getIn(errors, field.name);

    return (
      <div>
        <TagsInput
          value={field.value}
          className={clsx(`react-tagsinput `, {
            "is-invalid": touch && errorMessage,
          })}
          onChange={(target) => setFieldValue(field.name, target)}
        />
        <div className="invalid-feedback">{errorMessage}</div>
      </div>
    );
  }
}

export default TagsInputField;
