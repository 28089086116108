import React from "react";
import { connect } from "react-redux";
import ChatCard from "./components/ChatCard";

class Chat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
    };
  }

  componentWillMount() {
    const { isAuthenticated } = this.props.auth;
    if (isAuthenticated) this.setState({ loggedIn: true });
    else this.setState({ loggedIn: false });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { isAuthenticated } = nextProps.auth;
    if (isAuthenticated) return { loggedIn: true };
    else return { loggedIn: false };
  }

  render() {
    return (
      <main className="container-fluid p-5">
        {this.state.loggedIn && <ChatCard {...this.props} />}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.login,
});
export default connect(mapStateToProps, null)(Chat);
