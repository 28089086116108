import {
  IMPORTER_PARSE_FILE,
  IMPORTER_PARSE_FILE_SUCCESS,
  IMPORTER_PARSE_FILE_FAILURE,
} from "../actions/actionTypes";

import {
  setLoadingState,
  setSuccessResponse,
  setErrorResponse,
} from "./reducerUtils";

const initialState = {
  data: [],
  state: "INIT",
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case IMPORTER_PARSE_FILE:
      return setLoadingState(state);
    case IMPORTER_PARSE_FILE_SUCCESS:
      return setSuccessResponse(state, action);
    case IMPORTER_PARSE_FILE_FAILURE:
      return setErrorResponse(state, action.err);
    default:
      return state;
  }
}
