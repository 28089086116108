import React, { Component } from "react";
import ChatField from "./ChatField";
import moment from "moment";
import { Card, CardHeader, CardBody } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-solid-svg-icons";

class ChatUserCard extends Component {
  renderMessage = (message, idx) => {
    const { activeContact, currentUser } = this.props;
    const isSenderCurrentUser = currentUser.id === message.senderId;
    const senderInfo = isSenderCurrentUser ? currentUser : activeContact;

    let messageBody = null;

    if (message.message_type === "File") {
      messageBody = (
        <div>
          <div className="chat-box bg-first text-white">
            <p>Attachment</p>
            <Card className="mt-3 mb-2 pt-2 pb-2 text-center">
              <div>
                <a
                  href={message.fileUrl}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <FontAwesomeIcon
                    alt={message.fileName}
                    icon={faDownload}
                    size="lg"
                    className="mr-1 opacity-5"
                  />
                </a>
              </div>
            </Card>
          </div>
          <small className="mt-2 d-block text-black-50">
            <FontAwesomeIcon icon={faClock} className="mr-1 opacity-5" />
            {moment(message.createdAt).format("LLLL")}
          </small>
        </div>
      );
    } else {
      messageBody = (
        <div>
          <div className="chat-box bg-first text-white">
            <p>{message.message}</p>
          </div>
          <small className="mt-2 d-block text-black-50">
            <FontAwesomeIcon
              icon={["far", "clock"]}
              className="mr-1 opacity-5"
            />
            {moment(message.createdAt).format("LLLL")}
          </small>
        </div>
      );
    }
    return (
      <div
        key={`message-idx-${idx}`}
        className={`chat-item p-2 mb-2 ${
          isSenderCurrentUser ? "" : "chat-item-reverse"
        }`}
      >
        <div
          className={`align-box-row ${
            isSenderCurrentUser ? "" : "flex-row-reverse"
          }`}
        >
          <div className="avatar-icon-wrapper avatar-icon-lg align-self-start">
            <div className="avatar-icon rounded border-0">
              <img alt="..." src={senderInfo.picture} />
            </div>
          </div>
          {messageBody}
        </div>
      </div>
    );
  };

  render() {
    const { defaultMessage, activeContact } = this.props;
    return (
      <Card className="card-box mb-5">
        <CardHeader className="app-inner-content-layout--main__header bg-white p-4 border-bottom">
          <div className="card-header--title">
            <small>Messenger</small>
            {activeContact ? (
              <b>Chat with {activeContact.name}</b>
            ) : (
              <b> Select a contact </b>
            )}
          </div>
          <div className="card-header--actions" />
        </CardHeader>
        <CardBody>
          <PerfectScrollbar className="scroll-area-lg">
            <div className="chat-wrapper p-3">
              {activeContact &&
                activeContact.messages.map((message, idx) => {
                  return this.renderMessage(message, idx);
                })}
            </div>
          </PerfectScrollbar>
        </CardBody>
        <ChatField
          currentContact={activeContact}
          defaultMessage={defaultMessage}
        />
      </Card>
    );
  }
}

export default ChatUserCard;
