import {
  GET_OPPORTUNITIES,
  GET_OPPORTUNITIES_SUCCESS,
  GET_OPPORTUNITY,
  GET_OPPORTUNITY_SUCCESS,
  GET_OPPORTUNITY_FAILURE,
  ADD_OPPORTUNITY,
  ADD_OPPORTUNITY_SUCCESS,
  ADD_OPPORTUNITY_FAILURE,
  EDIT_OPPORTUNITY,
  EDIT_OPPORTUNITY_SUCCESS,
  EDIT_OPPORTUNITY_FAILURE,
  DELETE_OPPORTUNITY,
  DELETE_OPPORTUNITY_SUCCESS,
  DELETE_OPPORTUNITY_FAILURE,
} from "../actions/actionTypes";

import {
  setLoadingState,
  setSuccessResponse,
  setErrorResponse,
} from "./reducerUtils";

const initialState = {
  opportunities: null,
  opportunity: {
    data: [],
    state: "INIT",
    error: null,
  },
  isLoading: false,
};

export const opportunitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_OPPORTUNITIES:
      return {
        ...state,
        isLoading: true,
      };
    case GET_OPPORTUNITIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        opportunities: action.payload,
      };
    case GET_OPPORTUNITY:
      return setLoadingState(state, "opportunity", "GET");

    case GET_OPPORTUNITY_SUCCESS:
      return setSuccessResponse(state, action, "opportunity", "GET");

    case GET_OPPORTUNITY_FAILURE:
      return setErrorResponse(state, action.err, "opportunity", "GET");

    case ADD_OPPORTUNITY:
      return setLoadingState(state, "opportunity", "ADD");

    case ADD_OPPORTUNITY_SUCCESS:
      return setSuccessResponse(state, action, "opportunity", "ADD");

    case ADD_OPPORTUNITY_FAILURE:
      return setErrorResponse(state, action.err, "opportunity", "ADD");

    case EDIT_OPPORTUNITY:
      return setLoadingState(state, "opportunity", "EDIT");

    case EDIT_OPPORTUNITY_SUCCESS:
      return setSuccessResponse(state, action, "opportunity", "EDIT");

    case EDIT_OPPORTUNITY_FAILURE:
      return setErrorResponse(state, action.err, "opportunity", "EDIT");

    case DELETE_OPPORTUNITY:
      return setLoadingState(state, "opportunity", "DELETE");

    case DELETE_OPPORTUNITY_SUCCESS:
      return setSuccessResponse(state, action, "opportunity", "DELETE");

    case DELETE_OPPORTUNITY_FAILURE:
      return setErrorResponse(state, action.err, "opportunity", "DELETE");

    default:
      return state;
  }
};
